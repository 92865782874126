<template>
<b-modal id="new-task" @hide="resetModal" title="New Task" hide-footer size="md">
    <div class="p-3">
        <b-card v-if="claim != null" class="claim-preview-card mb-3">
            <b-row class="">
                <b-col cols="4">
                    <div class="d-flex flex-column">
                        <div class="label-text">CLAIM ID</div>
                        <div class="data-text">{{claim.claim_id}}</div>
                    </div>
                </b-col>
                <b-col cols="4">
                    <div class="d-flex flex-column">
                        <div class="label-text">PAYER</div>
                        <div class="data-text">{{claim.payer_name}}</div>
                    </div>
                </b-col>
                <b-col cols="4">
                    <div class="d-flex flex-column">
                        <div class="label-text">STATUS</div>
                        <div class="d-flex align-items-center">
                            <div :class="['status-icon',  claim.state.short]"></div>
                            <div class="data-text">{{claim.state.label}} </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <hr>
            <b-row class="">
                <b-col cols="4">
                    <div class="d-flex flex-column">
                        <div class="label-text">PATIENT</div>
                        <div class="data-text">{{claim.patient_name}}</div>
                    </div>
                </b-col>
                <b-col cols="4">
                    <div class="d-flex flex-column">
                        <div class="label-text">PROVIDER</div>
                        <div class="data-text">{{claim.provider_name}}</div>
                    </div>
                </b-col>
                <b-col v-if="issues" cols="4">
                    <div class="d-flex flex-column">
                        <div class="label-text">ISSUE</div>
                        <div class="claim-flag"><b-icon icon="exclamation-circle" class="mr-1"></b-icon>{{ claim.flag }}</div>
                    </div>
                </b-col>
            </b-row>
        </b-card>
        <b-form @submit="onSubmit">
            <b-row v-if="showAlert" class="error-text">
                <b-col>
                {{alertMessage}}
                </b-col>
            </b-row>
            <label v-if="hasPermission('assign_claims') && (workerId == null)" for="task-worker" class="selecter-label">Assigned</label>
            <b-form-group
                v-if="hasPermission('assign_claims') && (workerId == null)"
                id="task-worker">
                <b-input-group>
                    <worker-multiselect
                        v-model="task.assigned"
                        :allow-empty='true'
                        :allow-unassigned='false' 
                    />
                </b-input-group>
            </b-form-group>
            <label v-if="(claim == null)" for="task-claim" class="selecter-label">Claim</label>
            <b-form-group
                v-if="(claim == null)"
                id="task-claim">
                <b-input-group>
                    <multiselect 
                        openDirection="bottom"
                        v-model="task.claim_id" 
                        :options="worker_claims" 
                        placeholder="Select a claim id or leave empty" 
                        :show-labels="false"
                        @open="clearAssigned"
                        :allow-empty="true"
                        :internalSearch="true">
                    </multiselect>
                </b-input-group>
            </b-form-group>
            <label for="task-title" class="selecter-label">Title</label>
            <b-form-group
                id="task-title"
                style="text-align:left">
                <b-form-input v-model="task.title"
                    placeholder="Enter task title"
                    :state="fieldStates('title')"></b-form-input>
            </b-form-group>
            <b-row>
                <b-col cols="6">
                    <label for="task-due-date" class="selecter-label">Due Date</label>
                    <b-form-group id="task-due-date">
                        <b-form-input type="date" 
                        v-model="dueDate" 
                        placeholder="Choose a date"
                        :state="fieldStates('dueDate')"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col cols="6">
                    <label for="task-due-time" class="selecter-label">Due Time</label>
                    <b-form-group id="task-due-time">
                        <b-form-input type="time"
                            v-model="dueTime"
                            placeholder="Choose a time"
                            :state="fieldStates('dueTime')"
                            locale="en"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <label for="task-body" class="selecter-label">Description</label>
            <b-form-group
                id="task-body"
                style="text-align:left">
                <b-form-textarea v-model="task.body"
                placeholder="Enter task description"></b-form-textarea>
            </b-form-group>
            <div class="d-flex justify-content-end">
                <b-button @click="onSubmit" class="avo-primary-btn">
                    Create Task
                </b-button>
            </div>
        </b-form>
    </div>
</b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect'
import WorkerMultiselect from './../multiselects/WorkerMultiselect.vue'
import { make_url, authenticated_request, permissionsMixin, alertsMixin, } from '../../common.js'

export default {
  name: 'TaskAdd',
  mixins: [permissionsMixin, alertsMixin ],
  components: {
      WorkerMultiselect,
      Multiselect
  },
  props: ['selectedClaim', 'issues', 'workerId'],
  data: function(){
      return {
          task: {
              due_date: null,
              assigned: null,
              title: null,
              body: null,
          },
          dueDate: null,
          dueTime: '12:00',
          worker_claims: [],
          validator: {
              dueDate: null,
              dueTime: null,
              title: null,
          }
      }
  },
  async mounted(){
      if (this.claim == null){
          this.getWorkerClaims();
      }
  },
  methods: {
    onSubmit(){
        this.setValidator()
        if (this.validate()){
            this.addTask();
        }
    },
    addTask(){
      var data = {
          state: 0,
          title: this.task.title,
          body: this.task.body,
          follow_up: 1
      }
      data.due_date = this.dueDateTime;
    
      const self = this;
      var url;
      if (this.claim){
        url = make_url("/claims/" + this.claim.claim_id + "/tasks");
      }
      else if (this.task.claim_id){
        url = make_url("/claims/" + this.task.claim_id + "/tasks");
      }
      else{
        url = make_url("/claims/tasks");
      }
      const promise = authenticated_request({
        method: "post",
        url: url,
        data: data
      }).then(function(response){
        if (response){
            if (self.task.assigned){
                const url = make_url("/workers/" + self.task.assigned.worker_id + "/tasks");
                self.assign_task_to_worker(response.data, url);
            }
            else if (self.workerId){
                const url = make_url("/workers/" + self.workerId + "/tasks");
                self.assign_task_to_worker(response.data, url);
            }
            else if (self.user_worker_id) {
                const url = make_url("/workers/" + self.user_worker_id + "/tasks");
                self.assign_task_to_worker(response.data, url)
            }
            else{
                self.$bvModal.hide('new-task');
                self.$emit('submitted');
                self.$store.commit("refreshActivityLog");
                self.$store.commit("refreshTasks");
            }   
        }
      }).catch(function(errors){
          self.setShowAlert(true, errors);
      });
      return promise;
    },
    assign_task_to_worker(task_id, url){
      var data = {
          tasks: [task_id],
      }
      const self = this;
      const promise = authenticated_request({
        method: "post",
        url: url,
        data: data
      }).then(function(response){
        if (response){
            self.$bvModal.hide('new-task');
            self.$emit('submitted');
            self.$store.commit("refreshActivityLog");
            self.$store.commit("refreshTasks");
            vm.setShowAlert(false);
        }
      }).catch(function(errors){
        vm.setShowAlert(true, errors);
      });
      return promise;
    },
    getWorkerClaims: function(){
        const url = make_url("/workers/" + this.workerId + "/claims");
        const vm = this;
        const promise = authenticated_request({
            method: "get",
            url: url,
        }).then(function(response){
            //get unique claim_ids
            var worker_claims = [ ...new Set(response.data.claims.map(obj => obj.claim_id))]
            vm.worker_claims = worker_claims || [];
            vm.setShowAlert(false);
            return worker_claims || [];
        }).catch(function(errors) {
            vm.setShowAlert(true, errors);
        });
        
        return promise;
    },
    setValidator() {
        this.validator = {
            dueDate: !this.dueDate ? "required" : null,
            dueTime: !this.dueTime ? "required" : null,
            title: !this.task.title ? "required" : null,
        }
    },
    clearValidator() {
        this.validator = {
            dueDate: null,
            dueTime: null,
            title: null,
        }
    },
    validate() {
        return Object.values(this.validator).every(o => o === null);
    },
    fieldStates(field) {
        return this.validator[field] ? false : null;
    },
    resetModal(){
        this.task = {
            due_date: null,
            assigned: null,
            title: null,
            body: null,
        };
        this.dueDate = null;
        this.dueTime = '12:00';
        this.setShowAlert(false);
        this.clearValidator();
    },
    clearAssigned(){
        this.task.assigned = null
    },
  },
  computed: {
    user_worker_id: function() {
        return localStorage.getItem("worker_id");
    },
    dueDateTime: function() {
        var temp = this.dueDate + 'T' + this.dueTime;
        var newDate = new Date(temp)
        return newDate
    },
    claim() {
        return (this.$store.state.claims.workedClaim || this.selectedClaim);
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.error-text {
    color: #f05c50;
    font: 14px;
    text-align:center;
}
.avo-invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
.claim-flag {
    font-size: 14px;
    color: var(--red);
}
.claim-preview-card {
    background: #F3F8FD;
    border: 0px;
    border-radius: 5px;
}
.selecter-label {
    margin-top: 5px;
}
</style>