<template>
<b-modal :id="getModalID" title="Edit Task" hide-footer size="md">
    <div class="p-3">
        <b-card v-if="claim != null" class="claim-preview-card mb-3">
            <b-row class="">
                <b-col cols="4">
                    <div class="d-flex flex-column">
                        <div class="label-text">CLAIM ID</div>
                        <div class="data-text">{{claim.claim_id}}</div>
                    </div>
                </b-col>
                <b-col cols="4">
                    <div class="d-flex flex-column">
                        <div class="label-text">PAYER</div>
                        <div class="data-text">{{claim.payer_name}}</div>
                    </div>
                </b-col>
                <b-col cols="4">
                    <div class="d-flex flex-column">
                        <div class="label-text">STATUS</div>
                        <div class="d-flex align-items-center">
                            <div :class="['status-icon',  claim.state.short]"></div>
                            <div class="data-text">{{claim.state.label}} </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <hr>
            <b-row class="">
                <b-col cols="4">
                    <div class="d-flex flex-column">
                        <div class="label-text">PATIENT</div>
                        <div class="data-text">{{claim.patient_name}}</div>
                    </div>
                </b-col>
                <b-col cols="4">
                    <div class="d-flex flex-column">
                        <div class="label-text">PROVIDER</div>
                        <div class="data-text">{{claim.provider_name}}</div>
                    </div>
                </b-col>
                <b-col v-if="issues" cols="4">
                    <div class="d-flex flex-column">
                        <div class="label-text">ISSUE</div>
                        <div class="claim-flag"><b-icon icon="exclamation-circle" class="mr-1"></b-icon>{{ claim.flag }}</div>
                    </div>
                </b-col>
            </b-row>
        </b-card>
        <b-form @submit="onSubmit">
            <avo-alert :avoAlerts.sync="avoAlerts" />
            <label for="task-title" class="selecter-label">Title</label>
            <b-form-group
                id="task-title">
                <b-form-input v-model="taskForm.title"></b-form-input>
            </b-form-group>
            <label for="task-body" class="selecter-label">Body</label>
            <b-form-group
                id="task-body">
                <b-form-textarea v-model="taskForm.body"></b-form-textarea>
            </b-form-group>
            <div class="d-flex justify-content-end">
                <b-button @click="deleteTask" class="avo-danger-btn mr-2">
                    Delete Task
                </b-button>
                <b-button @click="onSubmit" class="avo-primary-btn">
                    Save Task
                </b-button>
            </div>
        </b-form>
    </div>
</b-modal>
</template>

<script>
import { make_url, authenticated_request, alertsMixin } from '../../common.js'
import AvoAlert from './../../components/AvoAlert.vue'

export default {
  name: 'TaskEdit',
  props: ['task', 'issues', 'screen'],
  mixins: [alertsMixin],
  components: {
    AvoAlert,
  },
  data: function(){
      return {
          taskForm: {
              title: null,
              body: null,
          }, 
      }
  },
  methods: {
    onSubmit() {
      const vm = this;
      var url;
      if (this.claim){
        url = make_url("/claims/" + this.claim.claim_id + "/tasks/" + this.task.task_id).toString();
      }
      else{
        url = make_url("/claims/tasks/" + this.task.task_id).toString();
      }
      const promise = authenticated_request({
        method: "put",
        url: url,
        data: vm.taskForm
      }).then(function(response){
        if (response){
            vm.$bvModal.hide(vm.getModalID);
            vm.$emit('submitted');
            vm.$store.commit("refreshActivityLog");
            vm.$store.commit("refreshTasks");
        }
      }).catch(function(errors){
          console.log(errors.response)
          vm.setShowAlert(true, errors);
      });
      return promise;
    },
    deleteTask() {
        const vm = this;
        var url;
        if (this.claim){
            url = make_url("/claims/" + this.claim.claim_id + "/tasks/" + this.task.task_id).toString();
        }
        else{
            url = make_url("/claims/tasks/" + this.task.task_id).toString();
        }
        const promise = authenticated_request({
            method: "delete",
            url: url,
        }).then(function(){
            vm.$bvModal.hide(vm.getModalID);
            vm.$emit('submitted');
            vm.$store.commit("refreshActivityLog");
            vm.$store.commit("refreshTasks");
        }).catch(function(errors){
            console.log(errors.response)
            vm.setShowAlert(true, errors);
        });
        return promise;
    }
  },
  watch: {
    task() {
        if(this.task){
                this.taskForm = {
                title: this.task.title,
                body: this.task.body,
            }
        }
    }
  },
  computed: {
    claim() {
        return this.$store.state.claims.workedClaim;
    },
    getModalID() {
        return 'edit-task' + (this.screen ? '-screen': '-summary')
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.error-text {
    color: #f05c50;
    font: 14px;
    text-align:center;
}
.avo-invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
.claim-flag {
    font-size: 14px;
    color: var(--red);
}
.claim-preview-card {
    background: #F3F8FD;
    border: 0px;
    border-radius: 5px;
}
.selecter-label {
    margin-top: 5px;
}
</style>