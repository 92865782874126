<template>
    <b-modal @hide="resetModal" id="new-worklog" title="Log Work" no-close-on-backdrop scrollable hide-footer size="lg">
    <div class="p-3">
        <b-card class="claim-preview-card mb-3">
            <b-row class="">
                <b-col cols="4">
                    <div class="d-flex flex-column">
                        <div class="label-text">CLAIM ID</div>
                        <div class="data-text">{{claim.claim_id}}</div>
                    </div>
                </b-col>
                <b-col cols="4" class="px-0">
                    <div class="d-flex flex-column">
                        <div class="label-text">PAYER</div>
                        <div class="data-text">{{claim.payer_name}}</div>
                    </div>
                </b-col>
                <b-col cols="4" class="">
                    <div class="d-flex flex-column">
                        <div class="label-text">STATUS</div>
                        <div class="d-flex align-items-center" style="margin-left:-10px">
                            <div :class="['status-icon',  claim.state.short]"></div>
                            <div class="data-text">{{claim.state.label}} </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <hr>
            <b-row class="">
                <b-col cols="4">
                    <div class="d-flex flex-column">
                        <div class="label-text">PATIENT</div>
                        <div class="data-text">{{claim.patient_name}}</div>
                    </div>
                </b-col>
                <b-col cols="4" class="px-0">
                    <div class="d-flex flex-column">
                        <div class="label-text">PROVIDER</div>
                        <div class="data-text">{{claim.provider_name}}</div>
                    </div>
                </b-col>
                <b-col cols="4" class="">
                    <div class="d-flex flex-column">
                        <div class="label-text">SUBSTATUS</div>
                        <div class="data-text">{{ claim.substatus_1 && optionsMap ? optionsMap.claim_substatuses[claim.substatus_1] : 'N/A'}}</div>
                    </div>
                </b-col>
            </b-row>
        </b-card>
        <b-form @submit="onSubmit">
            <b-form-group>
                <label class="selecter-label">Type of Work</label>
                <b-form-select
                    v-model="worklog.work_type"
                    :options="optionsMapItem('worklog_types')"
                    :state="fieldStates('work_type', 'work_type')"
                    required>
                    <template #first>
                    <b-form-select-option :value="null" disabled>-- Select a work type --</b-form-select-option>
                    </template>
                </b-form-select>
            </b-form-group>
            <div v-if="worklog.work_type">
            <div>
            Claim Status will become: <strong>{{worklogClaimStateName}}</strong>
            </div>
            <hr>
            <!-- Claim Submitted -->
            <div v-if="workTypeName == 'Claim Submitted'">
                <label class="selecter-label">Where?</label>
                <b-form-group>
                    <b-form-radio :state="fieldStates('submittal', 'submittal_type')" v-model="worklog.submittal_type" value="1" inline>Clearing house</b-form-radio>
                    <b-form-radio :state="fieldStates('submittal', 'submittal_type')" v-model="worklog.submittal_type" value="0" inline>Payer</b-form-radio>
                    <!-- <b-form-invalid-feedback>{{fieldErrMessages('submittal', 'submittal_type')}}</b-form-invalid-feedback> -->
                </b-form-group>
                <div v-if="worklog.submittal_type == 0">
                    <label class="selecter-label">Select Payer</label>
                    <b-form-group>
                        <payer-multiselect
                            v-model="multiselectVueModels.payer"
                            :multiple="false"
                            :allow-empty="false">
                        </payer-multiselect>
                    </b-form-group>
                    <label for="payer-addr" class="selecter-label">Payer Address</label>
                    <b-form-group>
                        <b-form-input id="payer-addr" v-model="worklog.payer_mailing_addr"></b-form-input>
                    </b-form-group>
                </div>
                <div v-if="worklog.submittal_type == 1">
                    <label class="selecter-label">Clearinghouse Name</label>
                    <b-form-group>
                        <!-- :state="fieldStates('payment', 'invoice_no')" -->
                        <b-form-input v-model="worklog.clearinghouse_name"></b-form-input>
                    </b-form-group>
                </div>
                <label class="selecter-label">Notes</label>
                <b-form-group>
                    <b-form-textarea
                        v-model="worklog.description"/>
                    <!-- <b-form-invalid-feedback>{{fieldErrMessages('submittal', 'description')}}</b-form-invalid-feedback> -->
                </b-form-group>
            </div>
            <!-- Called Insurance -->
            <div v-if="workTypeName == 'Called Insurance'">
                <label class="selecter-label">Select Payer</label>
                <b-form-group>
                    <payer-multiselect
                        v-model="multiselectVueModels.payer"
                        :multiple="false"
                        :allow-empty="false">
                    </payer-multiselect>
                </b-form-group>
                <label class="selecter-label">Payer Phone</label>
                <b-form-group>
                    <b-form-input v-model="worklog.payer_phone"></b-form-input>
                </b-form-group>
                <!-- <b-form-invalid-feedback>{{fieldErrMessages('called_ins', 'phone')}}</b-form-invalid-feedback> -->

                <b-row class="mb-3">
                    <b-col cols="6" class="">
                        <label class="selecter-label">Rep Name</label>
                        <b-form-input v-model="worklog.rep_name"></b-form-input>
                        <!-- <b-form-invalid-feedback>{{fieldErrMessages('called_ins', 'rep_name')}}</b-form-invalid-feedback> -->
                    </b-col>
                    <b-col cols="6" class="pl-0">
                        <label class="selecter-label">Reference Number</label>
                        <b-form-input v-model="worklog.reference_number"></b-form-input>
                        <!-- <b-form-invalid-feedback>{{fieldErrMessages('called_ins', 'rep_name')}}</b-form-invalid-feedback> -->
                    </b-col>
                </b-row>

                <label class="selecter-label">Issue</label>
                <b-form-group>
                    <b-form-input
                        placeholder="What was the issue?"
                        v-model="worklog.issue_text"></b-form-input>
                </b-form-group>
                <label class="selecter-label">Resolution</label>
                <b-form-group>
                    <b-form-input
                        placeholder="What action was taken/needs to be taken?"
                        v-model="worklog.resolution"></b-form-input>
                </b-form-group>
                
                <label class="selecter-label">Notes</label>
                <b-form-group>
                    <b-form-textarea v-model="worklog.description"></b-form-textarea>
                </b-form-group>
            </div>
            <!-- EOB Retrieved -->
            <div v-if="workTypeName == 'EOB Retrieved'">
                <label class="selecter-label">Received By</label>
                <b-form-group>
                    <b-form-radio v-model="worklog.payer_contact_type" value="0" inline>Payer Fax</b-form-radio>
                    <b-form-radio v-model="worklog.payer_contact_type" value="1" inline>Payer Mail</b-form-radio>
                    <b-form-radio v-model="worklog.payer_contact_type" value="2" inline>Payer Portal</b-form-radio>
                    <!-- <b-form-invalid-feedback>{{fieldErrMessages('eob_retrieved', 'where')}}</b-form-invalid-feedback> -->
                </b-form-group>
                <div class="avo-accordian-container my-3">
                    <b-button
                        variant="outline-primary"
                        :class="[docsAccordianVisible ? 'avo-accordian-open-btn' : 'avo-accordian-btn', ]"
                        :aria-expanded="docsAccordianVisible ? 'true' : 'false'"
                        aria-controls="docs-collapse"
                        @click="docsAccordianVisible = !docsAccordianVisible">
                        <div>Attachments</div>
                        <b-icon v-if="!docsAccordianVisible" icon="paperclip"></b-icon>
                        <b-icon v-else icon="x" scale="1.25"></b-icon>
                    </b-button>
                    <b-collapse id="docs-collapse" v-model="docsAccordianVisible" class="">
                        <b-card class="docs-accordian">
                            <div class="d-flex flex-column p-2">
                                <div v-if="!selectedDoc">
                                    <label class="selecter-label">Upload EOB</label>
                                    <b-form-group>
                                        <b-form-file
                                        v-model="file"
                                        :state="Boolean(file)"
                                        placeholder="Choose a file or drop it here..."
                                        drop-placeholder="Drop file here...">
                                        <template slot="file-name" slot-scope="{ names }">
                                            <b-badge class="document-badge">{{ names[0] }}</b-badge>
                                        </template>
                                        </b-form-file>
                                    </b-form-group>
                                </div>
                                <div v-if="!file">
                                    <label  class="selecter-label">Choose from existing attachments</label>
                                    <multiselect
                                        class="mb-3"
                                        v-model="selectedDoc"
                                        :options="claimDocs"
                                        :show-labels="false"
                                        placeholder="-- Select an Option --"
                                        track-by="document_id"
                                        label="filename">
                                    </multiselect>
                                </div>
                            </div>
                        </b-card>
                    </b-collapse>
                </div>
                <b-row class="mb-3">
                    <b-col cols="4" class="">
                        <label for="payment-amount" class="selecter-label">Amount</label>
                        <b-form-input id="payment-amount" :state="fieldStates('payment', 'amount')" v-model="worklog.payment.amount"></b-form-input>
                        <b-form-invalid-feedback>{{fieldErrMessages('payment', 'amount')}}</b-form-invalid-feedback>
                    </b-col>
                    <b-col cols="4" class="pl-0">
                        <label for="payment-date" class="selecter-label">Payment Date</label>
                        <b-form-input type="date"
                            id="payment-date"
                            :state="fieldStates('payment', 'payment_date')"
                            v-model="worklog.payment.payment_date"
                            placeholder=""
                            value-as-datetime
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                            today-button
                            reset-button
                            close-button/>
                    </b-col>
                    <b-col cols="4" class="pl-0">
                        <label for="payment-invoice-no" class="selecter-label">Check/EFT No.</label>
                        <b-form-input id="payment-invoice-no" :state="fieldStates('payment', 'invoice_no')" v-model="worklog.payment.invoice_no"></b-form-input>
                    </b-col>
                </b-row>
                <label class="selecter-label">Payment Status</label>
                <b-form-group>
                    <b-form-radio v-model="worklog.posted" value="0" inline>Posted</b-form-radio>
                    <b-form-radio v-model="worklog.posted" value="1" inline>Not Yet Posted</b-form-radio>
                    <!-- <b-form-invalid-feedback>{{fieldErrMessages('eob_retrieved', 'posted')}}</b-form-invalid-feedback> -->
                </b-form-group>
                <label class="selecter-label">Notes</label>
                <b-form-group>
                    <b-form-textarea placeholder="Was the EOB given to the poster to post?" v-model="worklog.description"></b-form-textarea>
                </b-form-group>
            </div>
            <!-- Patient Payment Received -->
            <div v-if="workTypeName == 'Patient Payment Received'">
                <div v-if="worklog.payment.payment_source == 1">
                    <b-form-group>
                        <label class="selecter-label">Payment type</label>
                        <b-form-select
                            v-model="worklog.payment.patient_payment_type"
                            :options="optionsMapItem('patient_payment_types')"
                            :state="fieldStates('payment', 'patient_payment_type')"
                            required>
                            <template #first>
                            <b-form-select-option :value="null" disabled>-- Select a payment type --</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group>
                        <label class="selecter-label">Payment method</label>
                        <b-form-select
                            v-model="worklog.payment.patient_payment_method"
                            :options="optionsMapItem('patient_payment_methods')"
                            :state="fieldStates('payment', 'patient_payment_method')"
                            required>
                            <template #first>
                            <b-form-select-option :value="null" disabled>-- Select a payment method --</b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                </div>
                <b-row class="mb-3">
                    <b-col cols="4" class="">
                        <label for="payment-amount" class="selecter-label">Amount</label>
                        <b-form-input id="payment-amount" :state="fieldStates('payment', 'amount')" v-model="worklog.payment.amount"></b-form-input>
                        <b-form-invalid-feedback>{{fieldErrMessages('payment', 'amount')}}</b-form-invalid-feedback>
                    </b-col>
                    <b-col cols="4" class="pl-0">
                        <label for="payment-date" class="selecter-label">Date</label>
                        <b-form-input type="date"
                            id="payment-date"
                            :state="fieldStates('payment', 'payment_date')"
                            v-model="worklog.payment.payment_date"
                            placeholder=""
                            value-as-datetime
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                            today-button
                            reset-button
                            close-button/>
                    </b-col>
                    <b-col cols="4" class="pl-0">
                        <label for="payment-invoice-no" class="selecter-label">Invoice No</label>
                        <b-form-input id="payment-invoice-no" :state="fieldStates('payment', 'invoice_no')" v-model="worklog.payment.invoice_no"></b-form-input>
                    </b-col>
                </b-row>
                <label class="selecter-label">Notes</label>
                <b-form-group>
                    <b-form-textarea v-model="worklog.description"></b-form-textarea>
                </b-form-group>
            </div>
            <!-- Medical Records Sent -->
            <div v-if="workTypeName == 'Medical Records Sent'">
                <label class="selecter-label">Select Payer</label>
                <b-form-group>
                    <payer-multiselect
                        v-model="multiselectVueModels.payer"
                        :multiple="false"
                        :allow-empty="false">
                    </payer-multiselect>
                </b-form-group>
                <label class="selecter-label">Method</label>
                <b-form-group>
                    <b-form-radio v-model="worklog.payer_contact_type" value="0" inline>Faxed</b-form-radio>
                    <b-form-radio v-model="worklog.payer_contact_type" value="1" inline>Mailed</b-form-radio>
                    <b-form-radio v-model="worklog.payer_contact_type" value="2" inline>Web Portal</b-form-radio>
                    <!-- <b-form-invalid-feedback>{{fieldErrMessages('eob_retrieved', 'where')}}</b-form-invalid-feedback> -->
                </b-form-group>
                <div v-if="worklog.payer_contact_type == 0">
                    <label class="selecter-label">Fax Number</label>
                    <b-form-group>
                        <b-form-input v-model="worklog.payer_fax"></b-form-input>
                    </b-form-group>
                </div>
                <div v-if="worklog.payer_contact_type == 1">
                    <label class="selecter-label">Mailing Address</label>
                    <b-form-group>
                        <b-form-input v-model="worklog.payer_mailing_addr"></b-form-input>
                    </b-form-group>
                </div>
                <div v-if="worklog.payer_contact_type == 2">
                    <label class="selecter-label">Portal</label>
                    <b-form-group>
                        <b-form-input v-model="worklog.payer_portal"></b-form-input>
                    </b-form-group>
                </div>
                <div class="avo-accordian-container my-3">
                    <b-button
                        variant="outline-primary"
                        :class="[docsAccordianVisible ? 'avo-accordian-open-btn' : 'avo-accordian-btn', ]"
                        :aria-expanded="docsAccordianVisible ? 'true' : 'false'"
                        aria-controls="docs-collapse"
                        @click="docsAccordianVisible = !docsAccordianVisible">
                        <div>Attachments</div>
                        <b-icon v-if="!docsAccordianVisible" icon="paperclip"></b-icon>
                        <b-icon v-else icon="x" scale="1.25"></b-icon>
                    </b-button>
                    <b-collapse id="docs-collapse" v-model="docsAccordianVisible" class="">
                        <b-card class="docs-accordian">
                            <div class="d-flex flex-column p-2">
                                <label class="selecter-label">Upload Records</label>
                                <b-form-group>
                                    <b-form-file
                                    v-model="files"
                                    multiple
                                    placeholder="Select file(s) or drop them here..."
                                    drop-placeholder="Drop file here...">
                                    <template slot="file-name" slot-scope="{ names }">
                                        <b-badge class="document-badge">{{ names[0] }}</b-badge>
                                        <b-badge v-if="names.length > 1" class="document-badge ml-1">
                                        + {{ names.length - 1 == 1 ? '1 more file' : names.length - 1 + ' more files' }}
                                        </b-badge>
                                    </template>
                                    </b-form-file>
                                </b-form-group>
                                <label class="selecter-label">Choose from existing attachments</label>
                                <multiselect
                                    class="mb-3"
                                    v-model="selectedDocs"
                                    :hide-selected="true"
                                    :options="claimDocs"
                                    :multiple="true"
                                    :show-labels="false"
                                    placeholder="-- Select an Option --"
                                    track-by="document_id"
                                    label="filename">
                                </multiselect>
                                
                            </div>
                        </b-card>
                    </b-collapse>
                </div>
                <label class="selecter-label">Notes</label>
                <b-form-group>
                    <b-form-textarea v-model="worklog.description"></b-form-textarea>
                </b-form-group>
            </div>
            <!-- Appealed CLaim -->
            <div v-if="workTypeName == 'Appealed Claim'">
                <label class="selecter-label">Select Payer</label>
                <b-form-group>
                    <payer-multiselect
                        v-model="multiselectVueModels.payer"
                        :multiple="false"
                        :allow-empty="false">
                    </payer-multiselect>
                </b-form-group>
                <label class="selecter-label">Method</label>
                <b-form-group>
                    <b-form-radio v-model="worklog.payer_contact_type" value="0" inline>Faxed</b-form-radio>
                    <b-form-radio v-model="worklog.payer_contact_type" value="1" inline>Mailed</b-form-radio>
                    <b-form-radio v-model="worklog.payer_contact_type" value="2" inline>Web Portal</b-form-radio>
                    <!-- <b-form-invalid-feedback>{{fieldErrMessages('eob_retrieved', 'where')}}</b-form-invalid-feedback> -->
                </b-form-group>
                <div v-if="worklog.payer_contact_type == 0">
                    <label class="selecter-label">Fax Number</label>
                    <b-form-group>
                        <b-form-input v-model="worklog.payer_fax"></b-form-input>
                    </b-form-group>
                </div>
                <div v-if="worklog.payer_contact_type == 1">
                    <label class="selecter-label">Mailing Address</label>
                    <b-form-group>
                        <b-form-input v-model="worklog.payer_mailing_addr"></b-form-input>
                    </b-form-group>
                </div>
                <div v-if="worklog.payer_contact_type == 2">
                    <label class="selecter-label">Portal</label>
                    <b-form-group>
                        <b-form-input v-model="worklog.payer_portal"></b-form-input>
                    </b-form-group>
                </div>

                <label class="selecter-label">Issue</label>
                <b-form-group>
                    <b-form-input
                        placeholder="What is being appealed?"
                        v-model="worklog.issue_text"></b-form-input>
                </b-form-group>
                <label class="selecter-label">Resolution</label>
                <b-form-group>
                    <b-form-input
                        placeholder="What is the resolution goal (i.e. “Payment of 37244”)"
                        v-model="worklog.resolution"></b-form-input>
                </b-form-group>
                <div class="avo-accordian-container my-3">
                    <b-button
                        variant="outline-primary"
                        :class="[docsAccordianVisible ? 'avo-accordian-open-btn' : 'avo-accordian-btn', ]"
                        :aria-expanded="docsAccordianVisible ? 'true' : 'false'"
                        aria-controls="docs-collapse"
                        @click="docsAccordianVisible = !docsAccordianVisible">
                        <div>Attachments</div>
                        <b-icon v-if="!docsAccordianVisible" icon="paperclip"></b-icon>
                        <b-icon v-else icon="x" scale="1.25"></b-icon>
                    </b-button>
                    <b-collapse id="docs-collapse" v-model="docsAccordianVisible" class="">
                        <b-card class="docs-accordian">
                            <div class="d-flex flex-column p-2">
                                <label class="selecter-label">Upload appeal/reconsideration forms</label>
                                <b-form-group>
                                    <b-form-file
                                    v-model="files"
                                    multiple
                                    placeholder="Select file(s) or drop them here..."
                                    drop-placeholder="Drop file here...">
                                    <template slot="file-name" slot-scope="{ names }">
                                        <b-badge class="document-badge">{{ names[0] }}</b-badge>
                                        <b-badge v-if="names.length > 1" class="document-badge ml-1">
                                        + {{ names.length - 1 == 1 ? '1 more file' : names.length - 1 + ' more files' }}
                                        </b-badge>
                                    </template>
                                    </b-form-file>
                                </b-form-group>
                                <label for="hcfa-attachments-dropdown" class="selecter-label">Choose from existing documents</label>
                                <multiselect
                                    id="hcfa-attachments-dropdown"
                                    class="mb-3"
                                    v-model="selectedDocs"
                                    :hide-selected="true"
                                    :options="claimDocs"
                                    :multiple="true"
                                    :show-labels="false"
                                    placeholder="-- Select an Option --"
                                    track-by="document_id"
                                    label="filename">
                                </multiselect>
                            </div>
                        </b-card>
                    </b-collapse>
                </div>
                <label class="selecter-label">Notes</label>
                <b-form-group>
                    <b-form-textarea v-model="worklog.description"></b-form-textarea>
                </b-form-group>
            </div>
            <!-- Print/Mail hcfa -->
            <div v-if="workTypeName == 'Print/Mail HCFA'">
                <label class="selecter-label">Already Mailed?</label>
                <b-form-group>
                    <b-form-radio v-model="worklog.hcfa_mailed" value="0" inline>No</b-form-radio>
                    <b-form-radio v-model="worklog.hcfa_mailed" value="1" inline>Yes</b-form-radio>
                    <!-- <b-form-invalid-feedback>{{fieldErrMessages('print_mail_hcfa', 'mailed')}}</b-form-invalid-feedback> -->
                </b-form-group>
                <div v-if="worklog.hcfa_mailed == '0'">
                    <label class="selecter-label">Location</label>
                    <b-form-group>
                        <b-form-input placeholder="Location of the HCFA's to be printed by staff"  v-model="worklog.hcfa_location"></b-form-input>
                    </b-form-group>
                </div>
                <div v-else-if="worklog.hcfa_mailed == '1'">
                    <label for="mailed-date" class="selecter-label">Date Mailed</label>
                    <b-form-group>
                        <!-- :state="fieldStates('hcfa_mailed', 'mailed_date')" -->
                        <b-form-input type="date"
                        id="mailed-date"
                        v-model="worklog.mailed_date"
                        placeholder=""
                        value-as-datetime
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                        today-button
                        reset-button
                        close-button/>
                    </b-form-group>
                </div>    
                <div class="avo-accordian-container my-3">
                    <b-button
                        variant="outline-primary"
                        :class="[docsAccordianVisible ? 'avo-accordian-open-btn' : 'avo-accordian-btn', ]"
                        :aria-expanded="docsAccordianVisible ? 'true' : 'false'"
                        aria-controls="docs-collapse"
                        @click="docsAccordianVisible = !docsAccordianVisible">
                        <div>Attachments</div>
                        <b-icon v-if="!docsAccordianVisible" icon="paperclip"></b-icon>
                        <b-icon v-else icon="x" scale="1.25"></b-icon>
                    </b-button>
                    <b-collapse id="docs-collapse" v-model="docsAccordianVisible" class="">
                        <b-card class="docs-accordian">
                            <div class="d-flex flex-column p-2">
                                <label class="selecter-label">Upload new attachments</label>
                                <b-form-group>
                                    <b-form-file
                                    v-model="files"
                                    multiple
                                    placeholder="Select file(s) or drop them here..."
                                    drop-placeholder="Drop file here...">
                                    <template slot="file-name" slot-scope="{ names }">
                                        <b-badge class="document-badge">{{ names[0] }}</b-badge>
                                        <b-badge v-if="names.length > 1" class="document-badge ml-1">
                                        + {{ names.length - 1 == 1 ? '1 more file' : names.length - 1 + ' more files' }}
                                        </b-badge>
                                    </template>
                                    </b-form-file>
                                </b-form-group>
                                <label for="hcfa-attachments-dropdown" class="selecter-label">Choose from existing attachments</label>
                                <multiselect
                                    id="hcfa-attachments-dropdown"
                                    class="mb-3"
                                    v-model="selectedDocs"
                                    :hide-selected="true"
                                    :options="claimDocs"
                                    :multiple="true"
                                    :show-labels="false"
                                    placeholder="-- Select an Option --"
                                    track-by="document_id"
                                    label="filename">
                                </multiselect>
                            </div>
                        </b-card>
                    </b-collapse>
                </div>
            </div>
            <!-- Print/Mail hcfa -->
            <div v-if="workTypeName == 'Corrected Patient Policies'">
                <label class="selecter-label">Rebilled?</label>
                <b-form-group>
                    <b-form-radio v-model="worklog.rebilled" value="0" inline>No</b-form-radio>
                    <b-form-radio v-model="worklog.rebilled" value="1" inline>Yes</b-form-radio>
                    <!-- <b-form-invalid-feedback>{{fieldErrMessages('print_mail_hcfa', 'mailed')}}</b-form-invalid-feedback> -->
                </b-form-group>
                <label class="selecter-label">Notes</label>
                <b-form-group>
                    <b-form-textarea
                        v-model="worklog.description"/>
                    <!-- <b-form-invalid-feedback>{{fieldErrMessages('submittal', 'description')}}</b-form-invalid-feedback> -->
                </b-form-group>
            </div>
            <!-- Secondary -->
            <div v-if="workTypeName == 'Secondary Insurance'">
                <label class="selecter-label">Select Payer</label>
                <b-form-group>
                    <payer-multiselect
                        v-model="multiselectVueModels.payer"
                        :multiple="false"
                        :allow-empty="false">
                    </payer-multiselect>
                </b-form-group>
                <label class="selecter-label">Submittal Method</label>
                <b-form-group>
                    <b-form-radio v-model="worklog.payer_contact_type" value="0" inline>Faxed</b-form-radio>
                    <b-form-radio v-model="worklog.payer_contact_type" value="1" inline>Mailed</b-form-radio>
                    <b-form-radio v-model="worklog.payer_contact_type" value="2" inline>Web Portal</b-form-radio>
                    <!-- <b-form-invalid-feedback>{{fieldErrMessages('eob_retrieved', 'where')}}</b-form-invalid-feedback> -->
                </b-form-group>
                <div v-if="worklog.payer_contact_type == 0">
                    <label class="selecter-label">Fax Number</label>
                    <b-form-group>
                        <b-form-input v-model="worklog.payer_fax"></b-form-input>
                    </b-form-group>
                </div>
                <div v-if="worklog.payer_contact_type == 1">
                    <label class="selecter-label">Mailing Address</label>
                    <b-form-group>
                        <b-form-input v-model="worklog.payer_mailing_addr"></b-form-input>
                    </b-form-group>
                </div>
                <div v-if="worklog.payer_contact_type == 2">
                    <label class="selecter-label">Portal</label>
                    <b-form-group>
                        <b-form-input v-model="worklog.payer_portal"></b-form-input>
                    </b-form-group>
                </div>
                <b-row>
                    <b-col cols="6">
                        <label for="mailed-date" class="selecter-label">Date Sent</label>
                        <b-form-group>
                            <!-- :state="fieldStates('hcfa_mailed', 'mailed_date')" -->
                            <b-form-input type="date"
                            id="mailed-date"
                            v-model="worklog.mailed_date"
                            placeholder=""
                            value-as-datetime
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                            today-button
                            reset-button
                            close-button/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6" class="d-flex align-items-center justify-content-center">
                        <label class="selecter-label mr-2">EOB Retrieved:</label>
                        <!-- :state="fieldStates('issue', 'verified')" -->
                        <b-form-checkbox
                            v-model="worklog.eob_retrieved"
                            class="selecter-label">
                        </b-form-checkbox>
                    </b-col>
                </b-row>

            </div>
            <!-- Corrected Claim -->
            <div v-if="workTypeName == 'Corrected Claim'">
                <label class="selecter-label">ICN Number</label>
                <b-form-group>
                    <b-form-input
                        placeholder="Claim # of previous claim submission"
                        v-model="worklog.icn_no"></b-form-input>
                </b-form-group>
                <label class="selecter-label">Issue</label>
                <b-form-group>
                    <b-form-input
                        placeholder="What was the issue?"
                        v-model="worklog.issue_text"></b-form-input>
                </b-form-group>
                <label class="selecter-label">Resolution</label>
                <b-form-group>
                    <b-form-input
                        placeholder="What was the resolution?"
                        v-model="worklog.resolution"></b-form-input>
                </b-form-group>
                <label class="selecter-label">Notes</label>
                <b-form-group>
                    <b-form-textarea v-model="worklog.description"></b-form-textarea>
                </b-form-group>
            </div>
            <!-- Payment Pending -->
            <div v-if="workTypeName == 'Payment Pending'">
                <label class="selecter-label">Payer</label>
                <b-form-group>
                    <payer-multiselect
                        v-model="multiselectVueModels.payer"
                        :multiple="false"
                        :allow-empty="false">
                    </payer-multiselect>
                </b-form-group>
                <label for="payer-addr" class="selecter-label">Payer Address</label>
                <b-form-group>
                    <b-form-input id="payer-addr" v-model="worklog.payer_mailing_addr"></b-form-input>
                </b-form-group>
                <!-- <b-form-invalid-feedback>{{fieldErrMessages('called_ins', 'phone')}}</b-form-invalid-feedback> -->
                <label class="selecter-label">Notes</label>
                <b-form-group>
                    <b-form-textarea v-model="worklog.description"></b-form-textarea>
                </b-form-group>
            </div>
            <!-- Ready for Sending -->
            <div v-if="workTypeName == 'Ready for Sending'">
                <label class="selecter-label">Action taken on claim</label>
                <b-form-group>
                    <b-form-input v-model="worklog.ready_for_sending_action"></b-form-input>
                </b-form-group>
                <label class="selecter-label">Notes</label>
                <b-form-group>
                    <b-form-textarea v-model="worklog.description"></b-form-textarea>
                </b-form-group>
            </div>
            <!-- Ready for Posting -->
            <div v-if="workTypeName == 'Ready for Posting'">
                <label class="selecter-label">Payer</label>
                <b-form-group>
                    <payer-multiselect
                        v-model="multiselectVueModels.payer"
                        :multiple="false"
                        :allow-empty="false">
                    </payer-multiselect>
                </b-form-group>
                <label for="payer-addr" class="selecter-label">Payer Address</label>
                <b-form-group>
                    <b-form-input id="payer-addr" v-model="worklog.payer_mailing_addr"></b-form-input>
                </b-form-group>
                <b-row class="mb-3">
                    <b-col cols="4" class="">
                        <label for="payment-amount" class="selecter-label">Amount</label>
                        <b-form-input id="payment-amount" :state="fieldStates('payment', 'amount')" v-model="worklog.payment.amount"></b-form-input>
                        <b-form-invalid-feedback>{{fieldErrMessages('payment', 'amount')}}</b-form-invalid-feedback>
                    </b-col>
                    <b-col cols="4" class="pl-0">
                        <label for="payment-date" class="selecter-label">Payment Date</label>
                        <b-form-input type="date"
                            id="payment-date"
                            :state="fieldStates('payment', 'payment_date')"
                            v-model="worklog.payment.payment_date"
                            placeholder=""
                            value-as-datetime
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                            today-button
                            reset-button
                            close-button/>
                    </b-col>
                    <b-col cols="4" class="pl-0">
                        <label for="payment-invoice-no" class="selecter-label">Check/EFT No.</label>
                        <b-form-input id="payment-invoice-no" :state="fieldStates('payment', 'invoice_no')" v-model="worklog.payment.invoice_no"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="8">
                        <label class="selecter-label">EOB Type</label>
                        <b-form-group>
                            <b-form-radio v-model="worklog.eob_type" value="0" inline>ERA</b-form-radio>
                            <b-form-radio v-model="worklog.eob_type" value="1" inline>Paper</b-form-radio>
                            <b-form-radio v-model="worklog.eob_type" value="2" inline>Portal</b-form-radio>
                            <!-- <b-form-invalid-feedback>{{fieldErrMessages('eob_retrieved', 'where')}}</b-form-invalid-feedback> -->
                        </b-form-group>
                    </b-col>
                    <b-col cols="4" class="d-flex align-items-center pl-0">
                         <!-- <b-form-invalid-feedback>{{fieldErrMessages('called_ins', 'phone')}}</b-form-invalid-feedback> -->
                            <label for="mailed-date" class="selecter-label mr-2">Notified Poster:</label>
                            <!-- :state="fieldStates('issue', 'verified')" -->
                            <b-form-checkbox
                                v-model="worklog.notified_poster"
                                class="selecter-label">
                            </b-form-checkbox>
                    </b-col>
                </b-row>
                <label class="selecter-label">Notes</label>
                <b-form-group>
                    <b-form-textarea v-model="worklog.description"></b-form-textarea>
                </b-form-group>
            </div>
            <!-- Clearinghouse Err/Rejected -->
            <div v-if="workTypeName == 'Clearinghouse Error/Rejected'">
                <label class="selecter-label">Clearinghouse Name</label>
                <b-form-group>
                    <!-- :state="fieldStates('payment', 'invoice_no')" -->
                    <b-form-input v-model="worklog.clearinghouse_name"></b-form-input>
                </b-form-group>
                <label class="selecter-label">Error Type</label>
                <b-form-group>
                    <b-form-select
                        v-model="worklog.clearinghouse_error_type"
                        :options="optionsMapItem('clearinghouse_error_types')"
                        required>
                        <template #first>
                        <b-form-select-option :value="null" disabled>-- Select an error type --</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>

                <label class="selecter-label">Issue</label>
                <b-form-group>
                    <b-form-input
                        placeholder="What was the issue"
                        v-model="worklog.issue_text"></b-form-input>
                </b-form-group>

                <label class="selecter-label">Resolution</label>
                <b-form-group>
                    <b-form-input
                        placeholder="What is the resolution?"
                        v-model="worklog.resolution"></b-form-input>
                </b-form-group>

                <label class="selecter-label">Notes</label>
                <b-form-group>
                    <b-form-textarea v-model="worklog.description"></b-form-textarea>
                </b-form-group>
            </div>
            <!-- Pending Adjustments/Write-Off -->
            <div v-if="workTypeName == 'Pending Adjustments/Write-Off'">
                <label class="selecter-label">Adjustment Type</label>
                <b-form-group>
                    <!-- :state="fieldStates('payment', 'patient_payment_type')" -->
                    <b-form-select
                        v-model="worklog.adjustment_type"
                        :options="optionsMapItem('adjustment_types')"
                        required>
                        <template #first>
                        <b-form-select-option :value="null" disabled>-- Select an adjustment type --</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
                <b-row>
                    <b-col cols="6">
                        <label class="selecter-label">Amount</label>
                        <b-form-input v-model="worklog.adjustment_amount"></b-form-input>
                    </b-col>
                    <b-col cols="6">
                        <label class="selecter-label">Adjustment Date</label>
                        <b-form-group>
                            <!-- :state="fieldStates('hcfa_mailed', 'mailed_date')" -->
                            <b-form-input type="date"
                            v-model="worklog.adjustment_date"
                            placeholder=""
                            value-as-datetime
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                            today-button
                            reset-button
                            close-button/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <label class="selecter-label">Reviewed/Approved By</label>
                <b-form-group>
                    <worker-multiselect 
                        :v-model="approvedWorker"
                        placeholder="-- Select a worker --" 
                    />
                </b-form-group>
                <label class="selecter-label">Notes</label>
                <b-form-group>
                    <b-form-textarea v-model="worklog.description"></b-form-textarea>
                </b-form-group>
            </div>
            <!-- Reviewed Denial -->
            <div v-if="workTypeName == 'Reviewed Denial'">
                <label class="selecter-label">Denial Type</label>
                <b-form-group>
                    <b-form-select :state="fieldStates('denial', 'denial_type')" v-model="worklog.denial_type" :options="optionsMapItem('denial_types')">
                        <template #first>
                        <b-form-select-option :value="null" disabled>-- Select a denial type --</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
                <label class="selecter-label">Issue</label>
                <b-form-group>
                    <b-form-input
                        placeholder="What was the issue?"
                        v-model="worklog.issue_text"></b-form-input>
                </b-form-group>
                <label class="selecter-label">Resolution</label>
                <b-form-group>
                    <b-form-input
                        placeholder="What was the resolution?"
                        v-model="worklog.resolution"></b-form-input>
                </b-form-group>
                <label class="selecter-label">Notes</label>
                <b-form-group>
                    <b-form-textarea v-model="worklog.description"></b-form-textarea>
                </b-form-group>
            </div>
            <!-- Claim Not on File -->
            <div v-if="workTypeName == 'Claim Not On File'">
                <label class="selecter-label">Verified</label>
                <b-form-group>
                    <b-form-input
                        placeholder="Where was this verified?"
                        v-model="worklog.verified_where"></b-form-input>
                </b-form-group>
                <label class="selecter-label">Is there a log of the claim in the clearinghouse?</label>
                <b-form-group>
                    <b-form-radio v-model="worklog.log_in_clearinghouse" value="0" inline>No</b-form-radio>
                    <b-form-radio v-model="worklog.log_in_clearinghouse" value="1" inline>Yes</b-form-radio>
                    <!-- <b-form-invalid-feedback>{{fieldErrMessages('print_mail_hcfa', 'attachments')}}</b-form-invalid-feedback> -->
                </b-form-group>
                <label class="selecter-label">Resubmitted</label>
                <b-form-group>
                    <b-form-radio v-model="worklog.payer_contact_type" value="0" inline>Faxed</b-form-radio>
                    <b-form-radio v-model="worklog.payer_contact_type" value="1" inline>Mailed</b-form-radio>
                    <b-form-radio v-model="worklog.payer_contact_type" value="2" inline>Web Portal</b-form-radio>
                    <!-- <b-form-invalid-feedback>{{fieldErrMessages('eob_retrieved', 'where')}}</b-form-invalid-feedback> -->
                </b-form-group>
                <div v-if="worklog.payer_contact_type == 0">
                    <label class="selecter-label">Fax Number</label>
                    <b-form-group>
                        <b-form-input v-model="worklog.payer_fax"></b-form-input>
                    </b-form-group>
                </div>
                <div v-if="worklog.payer_contact_type == 1">
                    <label class="selecter-label">Mailing Address</label>
                    <b-form-group>
                        <b-form-input v-model="worklog.payer_mailing_addr"></b-form-input>
                    </b-form-group>
                </div>
                <div v-if="worklog.payer_contact_type == 2">
                    <label class="selecter-label">Portal</label>
                    <b-form-group>
                        <b-form-input v-model="worklog.payer_portal"></b-form-input>
                    </b-form-group>
                </div>
                <div v-if="worklog.payer_contact_type">
                    <label class="selecter-label">Confirmation Number</label>
                    <b-form-group>
                        <b-form-input v-model="worklog.resubmittal_confirmation_no"></b-form-input>
                    </b-form-group>
                </div>
                <label class="selecter-label">Notes</label>
                <b-form-group>
                    <b-form-textarea v-model="worklog.description"></b-form-textarea>
                </b-form-group>
            </div>
            <!-- In Dispute -->
            <div v-if="workTypeName == 'In Dispute'">
                <b-row>
                    <b-col cols="6">
                        <label class="selecter-label">Date of last claim submission</label>
                        <b-form-group>
                            <!-- :state="fieldStates('hcfa_mailed', 'mailed_date')" -->
                            <b-form-input type="date"
                            v-model="worklog.last_submitted_date"
                            placeholder=""
                            value-as-datetime
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                            today-button
                            reset-button
                            close-button/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <label class="selecter-label">Reference Number</label>
                        <b-form-input v-model="worklog.reference_number"></b-form-input>
                    </b-col>
                </b-row>
                <label class="selecter-label">Issue</label>
                <b-form-group>
                    <b-form-input
                        placeholder="What was the issue?"
                        v-model="worklog.issue_text"></b-form-input>
                </b-form-group>
                <label class="selecter-label">Notes</label>
                <b-form-group>
                    <b-form-textarea v-model="worklog.description"></b-form-textarea>
                </b-form-group>
            </div>
            <!-- Coding  -->
            <div v-if="workTypeName == 'Coding'">
                <label class="selecter-label">Coding Error</label>
                <b-form-group>
                    <b-form-select v-model="worklog.coding_error" :options="optionsMapItem('coding_errors')">
                        <template #first>
                        <b-form-select-option :value="null" disabled>-- Select a coding error --</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
                <label for="cpt-code-dropdown" class="selecter-label">CPT Codes Changed</label>
                <multiselect
                    id="cpt-code-dropdown"
                    class="mb-3"
                    v-model="worklog.cpt_codes"
                    :hide-selected="true"
                    :options="claimCPTCodes"
                    :custom-label="customCPTCodeLabel"
                    :multiple="true"
                    :show-labels="false"
                    placeholder="-- Select an Option --">
                </multiselect>
                <label for="icd-code-dropdown" class="selecter-label">ICD Codes Changed</label>
                <multiselect
                    id="icd-code-dropdown"
                    class="mb-3"
                    v-model="worklog.icd_codes"
                    :hide-selected="true"
                    :options="claimICDCodes"
                    :custom-label="(code) => code.code"
                    :multiple="true"
                    :show-labels="false"
                    placeholder="-- Select an Option --">
                </multiselect>
                <label class="selecter-label">Codes updated by</label>
                <b-form-group>
                    <worker-multiselect
                        :v-model="codesWorker"
                        placeholder="-- Select a worker --" 
                    />
                </b-form-group>
                <div v-if="fieldStates('codes', 'has_codes') === false" class="avo-invalid-feedback" style="margin-top:-10px">Please select the updated code(s)</div>
                <label class="selecter-label">Reason</label>
                <b-form-group>
                    <b-form-textarea placeholder="Please specify a reason for the change" :state="fieldStates('codes', 'description')" v-model="worklog.description"></b-form-textarea>
                </b-form-group>
            </div>
            <!-- Resolved - Paid and Resolved - Other -->
            <div v-if="workTypeName == 'Resolved - Paid' || workTypeName == 'Resolved - Other'">
                <label class="selecter-label">Notes</label>
                <b-form-group>
                    <b-form-textarea v-model="worklog.description"></b-form-textarea>
                </b-form-group>
            </div>
            <!-- Insurance Reprocessing -->
            <div v-if="workTypeName == 'Insurance Reprocessing'">
                <label class="selecter-label">Payer</label>
                <b-form-group>
                    <payer-multiselect
                        v-model="multiselectVueModels.payer"
                        :multiple="false"
                        :allow-empty="false">
                    </payer-multiselect>
                </b-form-group>
                <label for="payer-addr" class="selecter-label">Payer Address</label>
                <b-form-group>
                    <b-form-input id="payer-addr" v-model="worklog.payer_mailing_addr"></b-form-input>
                </b-form-group>
                <!-- <b-form-invalid-feedback>{{fieldErrMessages('called_ins', 'phone')}}</b-form-invalid-feedback> -->
                <label class="selecter-label">Reference Number</label>
                <b-form-group>
                    <b-form-input v-model="worklog.reference_number"></b-form-input>
                </b-form-group>
                <label class="selecter-label">Issue</label>
                <b-form-group>
                    <b-form-input
                        placeholder="What was the issue?"
                        v-model="worklog.issue_text"></b-form-input>
                </b-form-group>
                <label class="selecter-label">Resolution</label>
                <b-form-group>
                    <b-form-input
                        placeholder="What was the resolution?"
                        v-model="worklog.resolution"></b-form-input>
                </b-form-group>
                <label class="selecter-label">Notes</label>
                <b-form-group>
                    <b-form-textarea v-model="worklog.description"></b-form-textarea>
                </b-form-group>
            </div>
            <!-- Other -->
            <div v-if="workTypeName == 'Other'">
                <label class="selecter-label">Notes</label>
                <b-form-group>
                    <b-form-textarea v-model="worklog.description"></b-form-textarea>
                </b-form-group>
            </div>
            <div v-if="worklog.work_type" class="avo-accordian-container my-4">
                <b-button
                    variant="outline-primary"
                    :class="[taskVisible ? 'avo-accordian-open-btn' : 'avo-accordian-btn', ]"
                    :aria-expanded="taskVisible ? 'true' : 'false'"
                    aria-controls="task-collapse"
                    @click="taskVisible = !taskVisible">
                    <div>Create Next Follow Up Task</div>
                    <b-icon v-if="!taskVisible" icon="check2-circle"></b-icon>
                    <b-icon v-else icon="x" scale="1.25"></b-icon>
                </b-button>
                <b-collapse id="task-collapse" v-model="taskVisible" class="">
                    <b-card class="task-accordian">
                        <div class="d-flex flex-column p-2">
                            <label v-if="hasPermission('assign_claims')" for="task-worker" class="selecter-label">Assigned</label>
                            <b-form-group
                                v-if="hasPermission('assign_claims')"
                                id="task-worker">
                                <b-input-group>
                                    <worker-multiselect
                                        v-model="taskAssignedWorker"
                                        placeholder="Select a worker or leave empty to assign self"
                                    />
                                </b-input-group>
                            </b-form-group>
                            <label class="selecter-label">Title</label>
                            <b-form-group>
                                <b-form-input v-model="worklog.task.title" :state="taskFieldStates('title')"></b-form-input>
                            </b-form-group>
                            <b-row>
                                <b-col cols="6">
                                    <label class="selecter-label">Due date</label>
                                    <b-form-group>
                                        <b-form-input type="date"
                                            v-model="taskDueDate"
                                            placeholder="Choose a date"
                                            :state="taskFieldStates('taskDueDate')"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                                            today-button
                                            reset-button
                                            close-button/>
                                    </b-form-group>
                                </b-col>
                                <b-col cols="6">
                                    <label class="selecter-label">Due time</label>
                                    <b-form-group>
                                        <b-form-input type="time"
                                            v-model="taskDueTime"
                                            placeholder="Choose a time"
                                            :state="taskFieldStates('taskDueTime')"
                                            locale="en"/>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <label class="selecter-label">Description</label>
                            <b-form-group>
                                <b-form-textarea v-model="worklog.task.body"></b-form-textarea>
                            </b-form-group>
                        </div>
                    </b-card>
                </b-collapse>
            </div>
            <div v-if="claimSubstatusOptions && worklog.work_type && workTypeName != 'Corrected Patient Policies' && workTypeName != 'Resolved - Other' && workTypeName != 'Resolved - Paid'">
                <label class="selecter-label">Add a Claim Sub-status</label>
                <b-form-group>
                    <b-form-select
                        v-model="substatus"
                        :options="claimSubstatusOptions"
                        required>
                        <template #first>
                        <b-form-select-option :value="null" disabled>-- Select a Substatus --</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-form-group>
            </div>
            <div v-else class="avo-text-light">
                No claim substatuses available for this transition
            </div>
            <div class="d-flex justify-content-between align-items-center">
                <div v-if="issues" class="d-flex align-items-center">
                    <label class="mr-2 mb-0" style="font-weight:500;font-size:14px;color:#C84C42;">Send this claim back to the Claims Dash?</label>
                    <b-form-checkbox
                        v-model="send_to_main">
                    </b-form-checkbox>
                </div>
                <div v-else class="d-flex align-items-center">
                    <label class="mr-2 mb-0" style="font-weight:500;font-size:14px;color:#C84C42;">Send this claim to the Issues Dash?</label>
                    <b-form-checkbox
                        v-model="send_to_issues">
                    </b-form-checkbox>
                </div>
                <b-button @click="onSubmit" :disabled="submitInProgress" variant="outline-primary" class="avo-primary-btn"><span v-if="taskVisible">Log Work and Create Task</span><span v-else>Log Work</span></b-button>
            </div>
        </div>
        </b-form>
    </div>
    </b-modal>
</template>

<script>
import { make_url, authenticated_request, permissionsMixin, } from '../../common.js'
import Multiselect from 'vue-multiselect'
import PayerMultiselect from '../multiselects/PayerMultiselect.vue'
import WorkerMultiselect from '../multiselects/WorkerMultiselect.vue'

export default {
    name: 'WorkLogAdd',
    mixins: [permissionsMixin,],
    components: { Multiselect, PayerMultiselect, WorkerMultiselect, },
    props: ['claim', 'issues'],
    data() {
        return {
            iconHover: false,
            multiselectVueModels: {
                payer: null,
            },
            worklog: {
                title: null,
                description: null,
                work_type: null,
                claim_id: null,
                payer_id: null,
                payer_name: null,
                payer_phone: null,
                payer_fax: null,
                payer_mailing_addr: null,
                payer_portal: null,

                // submittal (clearinghouse_name/payer_name and payer_address)
                submittal_type: null,

                // Called Insurance ( + payer_name, payer_phone)
                rep_name: null,
                reference_number: null,
                issue_text: null,
                resolution: null,

                // Eob Retrieved (docs + payment.amount,date,invoice# (check/ETF#))
                payer_contact_type: null,
                posted: null,

                // Patient Payment Received - see payments

                // Medical Records Send (payer_contact_type/name/fax/mail/portal + docs)

                // Appealed Claim (payer_contact_type/name/fax/mail/portal + issue/resolution + docs)

                // Print/mail hcfa (+ docs)
                hcfa_mailed: null,
                mailed_date: null,
                hcfa_location: null,
                
                // Corrected Patient Policies
                rebilled: null,

                // Secondary (payer_contact_type/name/fax/mail/portal + mailed_date)
                eob_retrieved: null,

                // Corrected (+ issue_text/resolution)
                icn_no: null,

                // Payment pending (payer_name and payer addr)

                // Ready for Sending
                ready_for_sending_action: null,

                // Ready for posting (payer_name,address + payment.amount,date,invoice# (check/ETF#))
                eob_type: null,
                notified_poster: null,

                // Clearinghouse Err/Rejected
                clearinghouse_name: null,
                clearinghouse_error_type: null,

                // Adjustments/Write-off
                adjustment_type: null,
                adjustment_date: null,
                adjustment_amount: null,
                approved_by_name: null,

                // Reviewed Denial
                denial_type: null,
                
                // Claim Not On File (+ payer_contact_type/name/fax/mail/portal)
                verified_where: null,
                log_in_clearinghouse: null,
                resubmittal_confirmation_no: null,

                // In Dispute ( reference_number + issue_text)
                last_submitted_date: null,

                //CPT codes Changed (reason = descripttion)
                coding_error: null,
                cpt_codes: [],
                icd_codes: [],
                codes_changed_by: null,

                // Resolved - just description

                // Insurance Reprocessing (payer_name/address + reference_number + issue/resolution)
                document_ids: null,
                filename: null,
                payment: {
                    invoice_no: null,
                    amount: null,
                    payment_source: null,
                    patient_payment_type: null,
                    patient_payment_method: null,
                    payer_payment_type: null,
                    payer_payment_method: null,
                    payment_date: null,
                },
                task: {
                    title: null,
                    due_date: null,
                    body: null,
                },
                task_worker_id: null,
                send_to_issues: false,
            },
            approvedWorker: null,
            codesWorker: null,

            claimDocs: [],
            docsAccordianVisible: false,
            selectedDoc: null,
            selectedDocs: [],
            file: null,
            files: [],
            document_ids: [],

            taskAssignedWorker: null,
            issueVerified: false,
            taskVisible: false,
            taskDueDate: null,
            taskDueTime: '12:00',

            submitInProgress: false,
            uploadPromises: [],

            substatus: null,
            send_to_issues: null,
            send_to_main: null,

            validator: {
                work_type: {
                    work_type: null
                },
                submittal:{
                    submittal_type: null,
                },
                payment: {
                    payment_source: null,
                    patient_payment_type: null,
                    patient_payment_method: null,
                    payer_payment_type: null,
                    payer_payment_method: null,
                    invoice_no: null,
                    amount: null,
                    payment_date: null,
                },
                codes: {
                    has_codes: null,
                    description: null,
                },
                followup: {
                    followup_type: null,
                    followup_outcome: null,
                    date_of_work: null,
                },
                resubmittal: {
                    submittal_type: null,
                    resubmittal_confirmation_id: null,
                },
                denial: {
                    denial_type: null,
                    date_of_work: null,
                    description: null,
                },
                issue: {
                    issue_type: null,
                    description: null,
                    verified: null,
                },
                issue_resolved: {
                    description: null,
                },
            },
            taskValidator: {
                title: null,
                taskDueDate: null,
                taskDueTime: null,
            }
        }
    },
    
    methods: {
        setValidator(payload) {
            this.validator = {
                work_type: {
                    work_type: !this.worklog.work_type ? "required" : null,
                },
                submittal:{
                    submittal_type: !this.worklog.submittal_type ? "required" : null,
                },
                payment: {
                    payment_source: !this.worklog.payment.payment_source ? "required" : null,
                    patient_payment_type: !this.worklog.payment.patient_payment_type ? "required" : null,
                    patient_payment_method: !this.worklog.payment.patient_payment_method ? "required" : null,
                    payer_payment_type: !this.worklog.payment.payer_payment_type ? "required" : null,
                    payer_payment_method: !this.worklog.payment.payer_payment_method ? "required" : null,
                    invoice_no: !this.worklog.payment.invoice_no ? "required" : null,
                    amount: !this.worklog.payment.amount ? " " : (payload && payload['payment']? payload['payment']['amount']: null),
                    payment_date: !this.worklog.payment.payment_date ? "required" : null,
                },
                codes: {
                    has_codes: (this.worklog.icd_codes.length===0 && this.worklog.cpt_codes.length===0) ? "required" : null,
                    description: !this.worklog.description ? "required" : null,
                },
                followup: {
                    followup_type: !this.worklog.followup_type ? "required" : null,
                    followup_outcome: !this.worklog.followup_outcome ? "required" : null,
                    date_of_work: !this.worklog.date_of_work ? "required" : null,
                },
                resubmittal:{
                    submittal_type: !this.worklog.submittal_type ? "required" : null,
                    resubmittal_confirmation_id: !this.worklog.resubmittal_confirmation_id ? "required" : null,
                },
                denial:{
                    denial_type: !this.worklog.denial_type ? "required" : null,
                    date_of_work: !this.worklog.date_of_work ? "required" : null,
                    description: !this.worklog.description ? "required" : null,
                },
                issue:{
                    issue_type: !this.worklog.issue_type ? "required" : null,
                    description: !this.worklog.description ? "required" : null,
                    verified: !this.issueVerified ? "required" : null,
                },
                issue_resolved: {
                    description: !this.worklog.description ? "required" : null,
                },
            },
            this.taskValidator = {
                title: !this.worklog.task.title ? "required" : null,
                taskDueDate: !this.taskDueDate ? "required" : null,
                taskDueTime: !this.taskDueTime ? "required" : null,

            }
        },
        clearValidator() {
            this.validator = {
                work_type: {
                    work_type: null
                },
                submittal:{
                    submittal_type: null,
                },
                payment: {
                    payment_source: null,
                    patient_payment_type: null,
                    patient_payment_method: null,
                    payer_payment_type: null,
                    payer_payment_method: null,
                    invoice_no: null,
                    amount: null,
                    payment_date: null,
                },
                codes: {
                    has_codes: null,
                    description: null,
                },
                followup: {
                    followup_type: null,
                    followup_outcome: null,
                    date_of_work: null,
                },
                resubmittal: {
                    submittal_type: null,
                    resubmittal_confirmation_id: null,
                },
                denial: {
                    denial_type: null,
                    date_of_work: null,
                    description: null,
                },
                issue: {
                    issue_type: null,
                    description: null,
                    verified: null,
                },
                issue_resolved: {
                    description: null,
                },
            }
            this.taskValidator = {
                task: {
                    title: null,
                    taskDueDate: null,
                    taskDueTime: null,
                },
            }
        },
        clearPaymentTypeMethod() {
            this.worklog.payment = {
                payment_source: this.worklog.payment.payment_source,
                patient_payment_type: null,
                patient_payment_method: null,
                payer_payment_type: null,
                payer_payment_method: null,
                invoice_no: null,
                amount: null,
                payment_date: null,
            },
            this.validator.payment = {
                payment_source: null,
                patient_payment_type: null,
                patient_payment_method: null,
                payer_payment_type: null,
                payer_payment_method: null,
                invoice_no: null,
                amount: null,
                payment_date: null,
            }
        },
        validate(work_type) {
            if(!work_type){
                return false;
            } else {
                return true;
            }
        },
        validateTask() {
            return Object.values(this.taskValidator).every(o => o === null);
        },
        fieldStates(object, field) {
            return this.validator[object][field] ? false : null;
        },
        taskFieldStates(field) {
            return this.taskValidator[field] ? false : null;
        },
        fieldErrMessages(object, field) {
            return this.validator[object][field] ? this.validator[object][field][0] : null;
        },
        onSubmit() {
            this.setValidator();
            if(!this.validate(this.worklog.work_type) || (!this.validateTask() && this.taskVisible)){
                return
            }

            this.submitInProgress = true;
            if(this.files.length > 0){
                for (var i=0; i < this.files.length; i++) {
                    console.log("submitting file "+ i)
                    this.submitDoc(this.files[i]);
                }
            }
            const vm = this;
            Promise.all(this.uploadPromises).then(function() {
                vm.$store.commit("refreshDocuments");
                var params = JSON.parse(JSON.stringify(vm.worklog))

                params.claim_id = vm.claim.claim_id;
                params.task.due_date = vm.taskDueDateTime;

                var selectedDocIds = vm.selectedDocs.map(a => a.document_id);
                var uploadedDocIds = vm.document_ids;
                var combinedDocIds = uploadedDocIds.concat(selectedDocIds);
                params.document_ids = combinedDocIds.toString()
                
                if(vm.issues){
                    params.issue_claim = 1;
                } else {
                    params.issue_claim = 0;
                }
                if (vm.worklog.cpt_codes) {
                    params.cpt_codes = vm.worklog.cpt_codes.map(a => a.code);
                }
                if (vm.worklog.icd_codes) {
                    params.icd_codes = vm.worklog.icd_codes.map(a => a.code);
                }
                if (vm.worklog.mailed_date){
                    var tempMailDate = vm.worklog.mailed_date + "T12:00:00"
                    params.mailed_date = new Date(tempMailDate) 
                }
                if (vm.worklog.last_submitted_date){
                    var tempSubmitDate = vm.worklog.last_submitted_date + "T12:00:00"
                    params.last_submitted_date = new Date(tempSubmitDate) 
                }
                if (vm.worklog.adjustment_date){
                    var tempAdjustmentDate = vm.worklog.adjustment_date + "T12:00:00"
                    params.adjustment_date = new Date(tempAdjustmentDate) 
                }
                if (vm.worklog.payment.payment_date){
                    var tempPayDate = vm.worklog.payment.payment_date + "T12:00:00"
                    params.payment.payment_date = new Date(tempPayDate) 
                }
                if (vm.taskAssignedWorker){
                    params.task_worker_id = vm.taskAssignedWorker.worker_id
                }
                
                const url = make_url("/worklogs");
                const promise = authenticated_request({
                    method: "post",
                    url: url,
                    data: params
                }).then(function(){
                    vm.$emit('logAdded');

                    vm.transitionClaimState(vm.worklogClaimState);
                    // send to regular dash or issues; add substatus
                    vm.updateClaim(vm.substatus);

                    if(vm.send_to_main || vm.send_to_issues){
                        vm.$router.push({ path: '/'})
                        vm.clearWorkedClaim()
                    }
                    vm.$store.commit("refreshActivityLog");
                    vm.$store.commit("refreshTasks");
                    vm.$store.commit("refreshWorkLogSummary");
                    vm.submitInProgress = false;
                    vm.$bvModal.hide('new-worklog');
                }).catch(function(error){
                    vm.handleErrors(error);
                    console.log(error.response)
                });
                return promise;
            });
        },
        transitionClaimState(newState){
            var transition = null;
            const newClaimStateName = this.claimStateIdToName(newState);
            if(newClaimStateName == 'Submitted To Payor'){
                transition = 'submit';
            } else if(newClaimStateName == 'Pending'){
                transition = 'pending';
            } else if(newClaimStateName == 'Rejected/Denied'){
                transition = 'reject_or_deny';
            } else if(newClaimStateName == 'AR Review'){
                transition = 'ar_review';
            } else if(newClaimStateName == 'Client/Management Assistance'){
                transition = 'client_mgmt_assistance';
            } else if(newClaimStateName == 'Posting'){
                transition = 'posting';
            } else if(newClaimStateName == 'Patient Balance'){
                transition = 'patient_balance';
            } else if(newClaimStateName == 'Claim Closed'){
                transition = 'close';
            }
            if (transition == null){
                return;
            }
            const url = make_url("/claims/" + this.claim.claim_id + "/transitions/" + transition);
            const vm = this;
            const promise = authenticated_request({
                method: "put",
                url: url,
            }).then(function(response){
                if (response) {
                    vm.claim.state.label = response.data.label;
                    vm.claim.state.short = response.data.short;
                    vm.claim.state.value = response.data.state;
                }
            }).catch(function(error){
                console.log(error.response)
            });
            return promise;

        },
        updateClaim(newSubstatus){
            const url = make_url("/claims/" + this.claim.claim_id);
            const vm = this;
            const promise = authenticated_request({
                method: "put",
                url: url,
                data: {
                    'substatus_1': vm.substatus ? vm.substatus : null,
                    'issue_dash': vm.send_to_issues ? 1 : 0
                }
            }).then(function(response){
                if (response) {
                    if(newSubstatus){
                        vm.claim.substatus_1 = newSubstatus;
                    }
                }
            }).catch(function(error){
                console.log(error.response)
            });
            return promise
        },
        clearWorkedClaim: function() {
            this.$store.commit("setRefresh", true);
            this.$store.commit("clearWorkedClaim")
        },
        submitDoc(file) {
            const vm = this;
            var url = make_url("/claims/" + this.claim.claim_id + "/documents");
            
            var data = {
                filename: file.name,
                type: this.workTypeName == 'EOB Retrived' ? 8 : null,
            }
            const promise = authenticated_request({
                method: "post",
                url: url,
                data: data
            }).then(function(response){
                const items = response.data;
                vm.document_ids.push(items.document_id);
                vm.uploadDoc(file, items.post_url, items.post_params);
            }, function(error){
                console.log(error)
                vm.$emit('docUploadError');
            });
            this.uploadPromises.push(promise);
        },
        uploadDoc(file, path, params){
            const vm = this;
            var bodyFormData = new FormData();
            bodyFormData.append('from', params.from)
            bodyFormData.append('token', params.token)
            bodyFormData.append('file', file)

            const fullUrl = new URL(path, process.env.VUE_APP_API_URL)
            const promise = authenticated_request({
                method: "post",
                url: fullUrl,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                data: bodyFormData
            }).then(function(){
                console.log("document uploaded")
            }).catch(function(error) {
                console.log(error.response);
                vm.$emit('docUploadError');
            });
            return promise;
        },
        handleErrors(error) {
            if (error.response.status == 400) {
                // handles field validation
                if ("payload" in error.response.data) {
                    const payload = error.response.data.payload;
                    this.setValidator(payload);
                }
            }
        },
        getDocuments(){
			const vm = this;
			const url = make_url("/reports/documents");
			const promise = authenticated_request({
				method: "get",
				url: url,
				params: {
                    claim_id: this.claim.claim_id,
                }
			}).then(function(response){
                vm.claimDocs = response.data.documents;
				return response.data.documents;
			}).catch(function (error){
                console.log(error)
            })
			return promise;
        },
        resetModal(clear_worklog_type = true){
            this.iconHover = false,
            this.worklog = {
                title: null,
                description: null,
                work_type: clear_worklog_type? null : this.worklog.work_type,
                claim_id: null,
                payer_id: null,
                payer_name: clear_worklog_type? null : this.worklog.payer_name,
                payer_phone: clear_worklog_type? null : this.worklog.payer_phone,
                payer_fax: clear_worklog_type? null : this.worklog.payer_fax,
                payer_mailing_addr: clear_worklog_type? null : this.worklog.payer_mailing_addr,
                payer_portal: null,

                // submittal (clearinghouse_name/payer_name and payer_address)
                submittal_type: null,

                // Called Insurance ( + payer_name, payer_phone)
                rep_name: null,
                reference_number: null,
                issue_text: null,
                resolution: null,

                // Eob Retrieved (+ docs+ payment.amount,date,invoice# (check/ETF#))
                payer_contact_type: null,
                posted: null,

                // Print/mail hcfa (+ docs)
                hcfa_mailed: null,
                mailed_date: null,
                hcfa_location: null,
                
                // Corrected Patient Policies
                rebilled: null,

                // Secondary (payer_contact_type/name/fax/mail/portal + mailed_date)
                eob_retrieved: null,

                // Corrected (+ issue_text/resolution)
                icn_no: null,

                // Ready for Sending
                ready_for_sending_action: null,

                // Ready for posting (payer_name,address + payment.amount,date,invoice# (check/ETF#))
                eob_type: null,
                notified_poster: null,

                // Clearinghouse Err/Rejected
                clearinghouse_name: null,
                clearinghouse_error_type: null,

                // Adjustments/Write-off
                adjustment_type: null,
                adjustment_date: null,
                adjustment_amount: null,
                approved_by_name: null,

                // Reviewed Denial
                denial_type: null,
                
                // Claim Not On File (+ payer_contact_type/name/fax/mail/portal)
                verified_where: null,
                log_in_clearinghouse: null,
                resubmittal_confirmation_no: null,

                // In Dispute ( reference_number + issue_text)
                last_submitted_date: null,

                //CPT codes Changed (reason = descripttion)
                coding_error: null,
                cpt_codes: [],
                icd_codes: [],
                codes_changed_by: null,

                document_ids: null,
                payment: {
                    invoice_no: null,
                    amount: null,
                    payment_source: null,
                    patient_payment_type: null,
                    patient_payment_method: null,
                    payer_payment_type: null,
                    payer_payment_method: null,
                    payment_date: null,
                },
                task: {
                    title: null,
                    due_date: null,
                    body: null,
                },
                // task_worker_id: null,
            },
            this.taskVisible = false
            this.taskDueDate = null,
            this.taskDueTime = '12:00',
            this.taskAssignedWorker = null,
            this.issueVerified = false,
            this.approvedWorker = null,
            this.codesWorker = null,

            this.claimDocs = clear_worklog_type ? [] : this.claimDocs,
            this.selectedDoc = null,
            this.selectedDocs = [],
            this.file = null,
            this.files = [],
            this.document_ids = [],
            this.submitInProgress = false,
            this.send_to_issues = null,
            this.send_to_main = null,
            this.substatus = null,
            this.clearValidator();
        },
        customCPTCodeLabel(code){
            if (code.short != null) {
                return code.code + " - " + code.short;
            } else {
                return code.code;
            }
        },
    },
    computed: {
        optionsMap() {
            return this.$store.state.claims.worklogOptionsMap;
        },
        optionsMapItem: function() { return (item) => {
                if (this.$store.state.claims.worklogOptionsMap != null) {
                    return this.$store.state.claims.worklogOptionsMap[item];
                }
                else {
                    return null;
                }
            }
        },
        claimSubstatusOptions: function() {
            return this.optionsMapItem('state_substatus_map')[this.worklogClaimState]   
        },
        claimICDCodes() {
            return this.claim.icd_codes;
        },
        claimCPTCodes() {
            return this.claim.cpt_codes;
        },
        user_worker_id: function() {
            return localStorage.getItem("worker_id");
        },
        taskDueDateTime: function() {
            var temp = this.taskDueDate + 'T' + this.taskDueTime;
            var newDate = new Date(temp)
            return newDate
        },
        claimStateNameToId: function() {
            return (claimStateName) => {
            // look through this.optionsMap.claim-states and return the key with corresponding value claimStateName
                for (var key in this.optionsMap.claim_states) {
                    if (this.optionsMap.claim_states[key] == claimStateName) {
                        return key;
                    }
                }
                return null;
            }
        },
        claimStateIdToName: function() {
            return (claimStateId) => {
                return this.optionsMap.claim_states[claimStateId];
            }
        },
        worklogClaimState() {
            // TODO: this should be moved to some central place with the options map
            // for getting the claim state change associated with each worklog
            const workType = this.workTypeName;
            if(workType == 'Claim Submitted' 
                        || workType == 'Print/Mail HCFA' 
                        || workType == 'Secondary Insurance' 
                        || workType == 'Claim Not On File' 
                        || workType == 'Coding'){
                return this.claimStateNameToId('Submitted To Payor');
            } else if(workType == 'Called Insurance' 
                        // || workType == 'Corrected Patient Policies'
                        || workType == 'Medical Records Sent' 
                        || workType == 'Appealed Claim' 
                        || workType == 'Corrected Claim' 
                        || workType == 'Payment Pending' 
                        || workType == 'Reviewed Denial' 
                        || workType == 'In Dispute' 
                        || workType == 'Insurance Reprocessing'){
                return this.claimStateNameToId('AR Review');
            } else if(workType == 'EOB Retrived' 
                        || workType == 'Patient Payment Received' 
                        || workType == 'Ready for Posting' 
                        || workType == 'Pending Adjustments/Write-Off'){
                return this.claimStateNameToId('Posting');
            } else if(workType == 'Ready for Sending'){
                return this.claimStateNameToId('Partial Payment');
            } else if(workType == 'Clearinghouse Error/Rejected'){
                return this.claimStateNameToId('Reduced Payment');
            } else if(workType == 'Resolved - Other' || workType == 'Resolved - Paid'){
                return this.claimStateNameToId('Claim Closed');
            } else if(workType == 'Other'){
                return this.claimStateNameToId('Client/Management Assistance');
            } else return this.claim.state.value
        },
        worklogClaimStateName() {
            return this.claimStateIdToName(this.worklogClaimState) || "n/a";
        },
        workTypeName() {
            if (this.optionsMap !== null && this.worklog.work_type !== null) {
                return this.optionsMap.worklog_types[this.worklog.work_type];
            }
            else return null;
        }
    },
    watch: {
        'worklog.work_type': function(newWorkTypeValue) {
            this.resetModal(false);
            const newWorkTypeName = this.optionsMap?.worklog_types[newWorkTypeValue];

            // this should be moved to some central place with the options map
            if(newWorkTypeName == "Patient Payment Received"){
                this.worklog.payment.payment_source = 1;
            } if(newWorkTypeName == "EOB Retrieved"){
                this.worklog.payment.payment_source = 2;
            } if(newWorkTypeName == "EOB Retrieved" 
                        || newWorkTypeName == "Medical Records Sent" 
                        || newWorkTypeName == "Appealed Claim" 
                        || newWorkTypeName == "Print/Mail HCFA"){
                if(this.claimDocs.length == 0){
                    this.getDocuments();
                }
            }
        },
        'worklog.payment.payment_source': function() {
            this.clearPaymentTypeMethod();
        },
        taskVisible: function() {
            if (!this.taskVisible) {
                this.taskValidator = {
                    title: null,
                    taskDueDate: null,
                    taskDueTime: null,
                };
                this.taskDueDate = null;
                this.taskDueTime = '12:00';
                this.worklog.task.title = null;
                this.worklog.task.body = null;
            }
        },
        'multiselectVueModels.payer': function() {
            if (this.multiselectVueModels.payer) {
                const payer = this.multiselectVueModels.payer;
                this.worklog.payer_id = payer.payer_id;
                this.worklog.payer_name = payer.name;
                this.worklog.payer_phone = payer.telephone;
                this.worklog.payer_mailing_addr = payer.full_address;
                this.worklog.payer_fax = payer.fax;
            }
        },
        claim() {
            if(this.claim){
                this.worklog.payer_id = this.claim.payer_id
            }
        },
        approvedWorker: function(worker) {
            if (worker){
                this.worklog.approved_by_name = worker.name
            } else {
                this.worklog.approved_by_name = null
            }   
        },
        codesWorker: function(worker) {
            if (worker){
                this.worklog.codes_changed_by = worker.name
            } else {
                this.worklog.codes_changed_by = null
            }            
        },
        file(newSingleFile) {
            if(newSingleFile){
                this.files.push(newSingleFile);
            } else if(this.files.length == 1) {
                this.files.pop();
            }
        },
        selectedDoc(newSingleDoc) {
            if(newSingleDoc){
                this.selectedDocs.push(newSingleDoc);
            } else if(this.selectedDocs.length == 1) {
                this.selectedDocs.pop();
            }
        },
    },
}
</script>

<style scoped>
.alert {
    background: #FDEEED 0% 0% no-repeat padding-box;
    border: 1px solid var(--red);
    border-radius: 5px;
    text-align: center;
    letter-spacing: 0px;
    color: var(--red);
}

.avo-invalid-feedback {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}
.claim-flag {
    font-size: 14px;
    color: var(--red);
}
.claim-preview-card {
    background: #F3F8FD;
    border: 0px;
    border-radius: 5px;
}
.task-subform {
    position:absolute;
    right: -330px;
    top: 200px;
    width: 350px;
    /* background: red; */
    z-index: 999;
}
::v-deep .modal-dialog-scrollable .modal-content {
    overflow: visible !important;
}
::v-deep .b-form-btn-label-control.form-control > label {
    font-size: 14px;
}
.selecter-label {
    margin-top: 5px;
}

</style>