<template>
<b-container class="p-0">
    <avo-alert :avoAlerts.sync="avoAlerts" />
    <b-row v-if="screen" align-v="center" align-h="between" class="px-5 py-3 sticky-top" style="background:white;z-index:40;">
      <b-col sm="12" md="6" lg="5" class="d-flex align-items-center">
        <b-input-group class="search-group">
            <b-input-group-prepend>
                <span class="input-group-text"><b-icon icon="search"></b-icon></span>
            </b-input-group-prepend>
            <b-form-input
                @keyup="debounceTasks"
                @search="debounceTasks"
                class="task-search-input pl-1"
                type="search" v-model="taskSearch"
                placeholder="Search"/>
          <b-input-group-append>
            <span class="divider"/>
            <span class="divider"/>
            <b-dropdown ref="dropdown" class="dropdown filter-dropdown" :boundary="'window'">
                <template #button-content>
                <div>
                <b-icon icon="plus-circle" class="mr-2"></b-icon>
                Add filter
                </div>
                </template>
                <b-dropdown-form class="d-flex flex-column align-items-start">
                <span style="font-size:20px;font-weight:300">Filters</span>
                
                <div v-if="hasPermission('assign_claims') && (workerId == null)">
                  <div class="selecter-label">Assigned To</div>
                  <worker-multiselect
                    v-model="filters.workers"
                    :allow-empty="true"
                    :allow-unassigned="true"
                  />
                </div>

                <div v-if="hasPermission('assign_claims') && (workerId != null)">
                  <div class="selecter-label">Claim</div>
                  <multiselect
                      :show-labels="false"
                      v-model="filters.claims"
                      :options="worker_claims"
                      :multiple="true"
                      :internalSearch="true"
                      placeholder="-- Search for claims --">
                  </multiselect>
                </div>

                <div class ="selecter-label">Due Date After</div>
                <b-form-input type="date"
                    v-model="filters.date_start" 
                    today-button
                    reset-button
                    close-button></b-form-input>
                
                <div class ="selecter-label">Due Date Before</div>
                <b-form-input type="date" 
                    v-model="filters.date_end" 
                    class="mb-4"
                    today-button
                    reset-button
                    close-button></b-form-input>
                
                <div class="d-flex justify-content-end mt-4">
                    <b-button @click="clearFilters()" variant="outline-primary" class="avo-basic-btn mr-2">Clear Filters</b-button>
                    <b-button @click="applyFilters" variant="outline-primary" class="avo-primary-btn">Apply</b-button>
                </div>
                </b-dropdown-form>
            </b-dropdown> 
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col sm="12" md="3" lg="4" >
        <b-tabs pills v-model="completionFilter" class="d-flex">
          <b-tab title="Incomplete" active />
          <b-tab title="Complete"/>
        </b-tabs>
      </b-col>
      <b-col sm="12" md="2" lg="3" class="text-right">
          <b-button v-b-modal.new-task class="avo-primary-btn">
                <b-icon icon="plus" class="mr-2"/>Add Task
            </b-button>
            <task-add @submitted="getTasks()" :claim-id="claimId" :issues="issues" :worker-id="workerId"/>
      </b-col>
    </b-row>
    <b-card v-if="embedded" class="header-card">
      <div class="d-flex flex-row justify-content-between">
        <div class="summary-card-header-txt pl-2">
          Tasks
        </div>
        <div @click="$emit('viewAllClicked')" class="avo-text-light pr-2 mt-1 cursor-pointer">
          View all Tasks <b-icon icon="chevron-right"></b-icon>
        </div>
      </div>
    </b-card>
    <b-card :class="embedded ? 'body-card' : 'body-card-screen'">
      <b-overlay :show="tasksLoading" variant="white" style="min-height:100px;" >
      <div v-if="tasks.length > 0">
        <div v-for="(task, index) in tasks" :key="index">
          <b-card v-if="completionFilter == task.state" :class="[embedded ? 'task-card-small' : 'task-card', {'shadow': !taskData}, {'mx-3': taskData}]">
              <b-row v-if="screen" @click="selectTask(index)" class="cursor-pointer">
                <b-col cols="6" >
                  <div class="d-flex justify-content-start align-items-center">
                    <b-iconstack  @click.stop="transitionTask(task, index)" class="mr-3 mb-2">
                      <b-icon class="check-circle-fill" font-scale="1.5" icon="circle-fill"></b-icon>
                      <b-icon class="check-circle" font-scale="1.5" icon="circle"></b-icon>
                      <b-icon v-if="task.state == 1" shift-h="1.5" shift-v="-2" font-scale="1.25" icon="check"></b-icon>
                    </b-iconstack>
                    <span :style="[{fontSize: screen?'16px':'14px'}]">{{task.title}}</span>
                  </div>
                </b-col>
                <b-col cols="6" class="avo-text-light text-right">
                  <b-row>
                    <b-col cols="3" class="p-0 text-left">
                      <span :class="dueDateClass(task)" v-if="dueDateClass(task) == 'past-due'">Past Due</span>
                      <span :class="dueDateClass(task)" v-else-if="dueDateClass(task) == 'soon-due'">Due Soon </span>
                    </b-col>
                    <b-col cols="5" class="p-0 text-left">
                      <b-icon icon="calendar" class="mx-1"/>{{formatFullDate(task.due_date)}}
                    </b-col>
                    <b-col cols="3" class="p-0 text-left">
                      <b-icon icon="clock" class="mx-1"/>{{formatTime(task.due_date)}}
                    </b-col>
                    <b-col cols="1" class="p-0 text-left">
                      <b-icon v-if="!taskData" v-b-modal="'edit-task'+ (screen ? '-screen': '-summary')" @click.stop="setTaskToEdit(task)" icon="three-dots-vertical" class="mx-1 cursor-pointer"/>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-else @click="selectTask(index)">
                <b-col>
                  <b-row>
                    <b-col class="avo-text-light-small d-flex justify-content-between align-items-center mb-2">
                      <div>
                        <b-icon icon="calendar" class="mx-1"/>{{formatFullDate(task.due_date)}}
                        <b-icon icon="clock" class="mx-1"/>{{formatTime(task.due_date)}}
                      </div>
                      <div>
                        <span :class="dueDateClass(task)" v-if="dueDateClass(task) == 'past-due'">Past Due</span>
                        <span :class="dueDateClass(task)" v-else-if="dueDateClass(task) == 'soon-due'">Due Soon </span>
                        <b-icon v-if="!taskData" v-b-modal="'edit-task'+ (screen ? '-screen': '-summary')" @click.stop="setTaskToEdit(task)" icon="three-dots-vertical" class="ml-4 cursor-pointer"/>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row class="cursor-pointer">
                    <b-col class="d-flex justify-content-start align-items-center">
                      <b-iconstack v-if="!taskData" @click.stop="transitionTask(task, index)" class="mr-3 mb-2">
                        <b-icon class="check-circle-fill" font-scale="1.5" icon="circle-fill"></b-icon>
                        <b-icon class="check-circle" font-scale="1.5" icon="circle"></b-icon>
                        <b-icon v-if="task.state == 1" shift-h="1.5" shift-v="-2" font-scale="1.25" icon="check"></b-icon>
                      </b-iconstack>
                      <div v-else style="font-weight:500;margin-left:16px;margin-right:8px;">#{{task.claim_id}}  -</div>
                      <span :style="[{fontSize: screen?'16px':'14px'}]">{{task.title}}</span>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            <div v-if="clicked[index]" :class="[embedded ? 'task-body-small' : 'task-body']" :style="[{fontSize: screen?'16px':'14px'}]">
              {{task.body}}
            </div>
            <div v-if="clicked[index]" :class="[embedded ? 'worker-select-small' : 'worker-select']" style="">
              <div :class="{'mr-4':screen, 'mr-1':embedded, 'd-flex':true, 'flex-column':true }">
                <span class="label-text" :style="[{fontSize: screen?'14px':'12px'}]">Assigned to</span>
                <div class="data-card">
                  <b-iconstack class="mr-2" style="color:#519eff">
                    <b-icon icon="circle" scale="1.8"></b-icon>
                    <b-icon icon="person" scale="1.2"></b-icon>
                  </b-iconstack>
                  <span :style="[{fontSize: screen?'14px':'12px'}]">
                    {{task.name}}
                  </span>
                </div>
              </div>
              <div :class="{'mr-4':screen, 'mr-1':embedded, 'd-flex':true, 'flex-column':true }">
                <span class="label-text" :style="[{fontSize: screen?'14px':'12px'}]">Added on</span>
                <div class="data-card">
                  <b-iconstack class="mr-2" style="color:#519eff">
                    <b-icon icon="circle" scale="1.8"></b-icon>
                    <b-icon icon="calendar-4" scale="1"></b-icon>
                  </b-iconstack>
                  <span :style="[{fontSize: screen?'14px':'12px'}]" >
                    {{formatDateShort(task.created)}}
                  </span>
                </div>
              </div>
              <div class="d-flex flex-column">
                <span class="label-text" :style="[{fontSize: screen?'14px':'12px'}]">Claim</span>
                <div class="data-card">
                  <b-iconstack class="mr-2" style="color:#519eff">
                    <b-icon icon="circle" scale="1.8"></b-icon>
                    <b-icon icon="file-earmark-medical" scale="1"></b-icon>
                  </b-iconstack>
                  <span :style="[{fontSize: screen?'14px':'12px'}]" >
                    {{task.claim_id || '-'}}
                  </span>
                </div>
              </div>
            </div>
          </b-card>
        </div>
        <task-edit :screen="screen" v-if="!taskData" @submitted="getTasks()" :task="taskToEdit" :issues="issues"/>
      </div>
      <div v-if="tasks.length == 0 && !tasksLoading" class="no-tasks pt-4">
          No tasks found
      </div>
      </b-overlay>
    </b-card>
</b-container>
</template>


<script>
import Multiselect from 'vue-multiselect'
import WorkerMultiselect from './../multiselects/WorkerMultiselect.vue'
import TaskAdd from "./TaskAdd.vue"
import TaskEdit from "./TaskEdit.vue"
import AvoAlert from "./../../components/AvoAlert.vue"
import { make_url, authenticated_request, 
         debounce, formatDate, formatTime, formatFullDate, formatDateShort,
         permissionsMixin, alertsMixin, 
       } from '../../common.js'

export default {
  name: 'TaskList',
  mixins: [permissionsMixin, alertsMixin],
  components: { 
        Multiselect,
        AvoAlert,
        WorkerMultiselect,
        TaskAdd,
        TaskEdit,
    },
  props: [
    'claimId', 
    'screen',//false (or null)- task list embedded component; true- task list screen
    'workerOptionsLimit',
    'issues',
    // for the worker summary screen
    'workerId',
    'taskData',
    'showClaimId',
  ],
  data(){
      return {
        tasks: [],
        clicked: [],
        embedded: !this.screen,
        taskSearch: "",
        workerSearch: "",
        workerSelection: false,
        taskSelectable: true,
        filters: {
          date_start: null,
          date_end: null,
          states: [],
          workers: [],
          claims: [],
        },
        states: [],
        worker_claims: [],
        completionFilter: 0,
        taskToEdit: null,
        tasksLoading: true,
        perPageVar: this.screen ? null : 4,
        currentPage: 1,
      }
  },
  methods: {
    getTasks: function(){
        var params = {
            per_page: this.perPageVar,
            page: this.currentPage,
        }
        if (this.claimId != null){
          params.claim_id = this.claimId;
        }
        if (this.taskSearch){
          params.search = this.taskSearch;
        }
        if(this.sortBy){
            params.sort = this.sortBy + ":" + (this.sortDesc?"desc":"asc")
        }
        if (this.workerId != null){
          params.worker_id = this.workerId.toString();
        }
        // If not workerId is not set, non-admins can only get tasks assigned to themselves
        else if(!this.hasPermission('assign_claims') && localStorage.getItem("worker_id") != null){
            params.worker_id = localStorage.getItem("worker_id");
        }

        params.state = this.completionFilter;

        if (this.filters.date_start){
          params.date_start = this.filters.date_start + "T00:00:00+00:00";
        }
        if (this.filters.date_end){
          params.date_end = this.filters.date_end + "T00:00:00+00:00";
        }
        if (this.filters.workers.length > 0){
          var worker_ids = [];
          for (var index = 0; index < this.filters.workers.length; index++){
            worker_ids.push(this.filters.workers[index].worker_id);
          }
          params.worker_ids = worker_ids;
        }
        if (this.filters.claims.length > 0){
          params.claim_ids = this.filters.claims.join(",");
        }
        const url = make_url("/reports/claims/tasks");
        const self = this;
        const promise = authenticated_request({
            method: "get",
            url: url,
            params: params
        }).then(function(response){
            const items = response.data.tasks;
            if (items){
              self.tasks = items;
            }
            else{
              self.tasks = [];
            }
            self.rows = response.data.pagination.total;
            self.currentPage = response.data.pagination.page;
            self.perPageVar = response.data.pagination.per_page;
            self.setShowAlert(false);
            self.tasksLoading = false;
            
            return items || [];
        }).catch(function(error){
          self.setShowAlert(true, error);
          self.tasks = [];
        }).finally(()=>{
          self.tasksLoading = false;
        });
        
        return promise;
    },
    transitionTask(task, index) {
      var url = make_url("/claims/" + task.claim_id + "/tasks/" + task.task_id + "/transitions/complete");
      if (task.state == 1){
        url = make_url("/claims/" + task.claim_id + "/tasks/" + task.task_id + "/transitions/incomplete");
      }
      this.tasks[index].state = task.state == 1 ? 0 : 1;
      const vm = this;  
      const promise = authenticated_request({
        method: "PUT",
        url: url,
      }).then(function(){
          vm.getTasks();
          vm.$store.commit("refreshActivityLog");
          vm.$store.commit("refreshTasks");
      }).catch(function(errors){
          this.tasks[index].state = task.state == 1 ? 0 : 1;
          console.log(errors.response)
          self.setShowAlert(true, errors);
      });
      return promise;
    },
    formatDate,
    formatFullDate,
    formatDateShort,
    formatTime,
    dueDateClass(task) {
        const today = new Date();
        const dueDate = new Date(task.due_date)
        const dayDiff = Math.floor((dueDate - today) / (1000*60*60*24))
        if(dayDiff < 0){
          return 'past-due'
        } else if (dayDiff <= 3){
          return 'soon-due'
        } 
    },
    selectTask: function(index){
      //task can't be unselected when worker selection is opened, 
      //or unselected immediately after worker selection is closed
      if (!this.workerSelection){
        if (this.taskSelectable){
          this.$set(this.clicked, index, (!this.clicked[index]));
        }
        else{
          this.taskSelectable = true;
        }
      }
      else{
        if (this.taskSelectable){
          this.taskSelectable = false;
        }
      }
    },
    setTaskToEdit(task) {
      this.taskToEdit = task;
    },
    changeAssignedWorker: function(worker, id){
      var task = this.tasks[id];
      if (task.worker_id){
        const url = make_url("/workers/" + task.worker_id + "/tasks/" + task.task_id).toString();
        const vm = this;
        const promise = authenticated_request({
            method: "delete",
            url: url,
        }).then(function(response){
          if (response){
            vm.setShowAlert(false);
            task.worker_id = null;
            task.given_name = "";
            task.nick_name = "";
            task.family_name = "";
            task.name = "Unassigned";
            task.initials = "";
            vm.tasks[id] = task;
            if (worker.worker_id){
              vm.assignNewWorker(worker, task, id);
            }
            else{
              vm.setWorkerSelection(false);
              vm.taskSelectable = true;
            }
          }
        }).catch(function(errors) {
            vm.setShowAlert(true, errors);
        });
        
        return promise;
      }
      else{
        this.assignNewWorker(worker, task, id);
      }
    },
    assignNewWorker: function(worker, task, id){
      var data = {
          tasks: [task.task_id],
      }
      const vm = this;
      const url = make_url("/workers/" + worker.worker_id + "/tasks");
      const promise = authenticated_request({
        method: "post",
        url: url,
        data: data
      }).then(function(response){
        if (response){
          vm.setShowAlert(false);
          task.worker_id = worker.worker_id;
          task.given_name = worker.given_name;
          task.nick_name = worker.nick_name;
          task.family_name = worker.family_name;
          task.name = worker.name;
          task.initials = worker.initials;
          vm.tasks[id] = task;
          vm.setWorkerSelection(false);
          vm.taskSelectable = true;
        }
      }).catch(function(errors){
          vm.setShowAlert(true, errors);
      });
      return promise;
    },
    getWorkerClaims: function(){
        const url = make_url("/workers/" + this.workerId + "/claims");
        const vm = this;
        const promise = authenticated_request({
            method: "get",
            url: url,
        }).then(function(response){
            //get unique claim_ids
            var worker_claims = [ ...new Set(response.data.claims.map(obj => obj.claim_id))]
            vm.worker_claims = worker_claims || [];
            vm.setShowAlert(false);
            return worker_claims || [];
        }).catch(function(errors) {
            vm.setShowAlert(true, errors);
        });
        
        return promise;
    },
    setWorkerSelection: function(bool){
      this.workerSelection = bool;
    },
    clearFilters: function(){
        this.filters = {
          date_start: null,
          date_end: null,
          states: [],
          workers: [],
          claims: [],
        };
      this.getTasks();
    },
    applyFilters(){
      this.getTasks();
      this.$refs.dropdown.hide();
    },
  },
  computed: {
    refreshTaskList() {
      return this.$store.state.claims.reloadTasks;
    },
    refreshTaskSummary() {
      return this.$store.state.claims.reloadTasksSummary;
    },
  },
  watch: {
    claimId: function(){
      this.getTasks();
    },
    taskSearch: function(){
      this.debounceTasks();
    },
    tasks: function(){
      this.clicked = []
      if (this.tasks){
        for (var index = 0; index < this.tasks.length; index++){
          this.clicked.push(false);
        }
      }
    },
    taskData: function(){
        this.tasks = this.taskData;
        this.tasksLoading = false;
    },
    completionFilter: function(){
        this.clearFilters();
    },
  },
  // activated is only fired when a kept-alive component is navigated to
  activated(){
    // we need a separate refresh (distinct from the task-edit/add 'submitted' event) for tasks created from worklog
    if(this.refreshTaskList && this.screen){
      this.getTasks();
      this.$store.commit("tasksRefreshed");
    }
    else if(this.refreshTaskSummary && !this.screen){
      this.getTasks();
      this.$store.commit("tasksSummaryRefreshed");
    }
  },
  created(){
    // if task data comes from parent component (worker summary)
    if(this.taskData){
      this.tasks = this.taskData;
      this.tasksLoading = false;
    } else {
      this.getTasks();
      this.debounceTasks = debounce(this.getTasks, 900);
      if (this.workerId){
        this.getWorkerClaims();
      }
    }
  },
  async mounted() {
      var self = this;
      authenticated_request({
          method: "get",
          url: make_url("/lookup/tasks/states")
      }).then(function(response){
          const states = Object.values(response.data);
          for (var index = 0; index < states.length; index++){
            self.states.push({'state': index, 'name': states[index]});
          }
      }).catch(function(errors){
        vm.setShowAlert(true, errors);
      });
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.no-tasks {
  font-size: 16px;
  font-weight: 200;
  text-align: center;
  height: 100px;
}
.header-card{
  border-radius: 5px 5px 0px 0px;
  border: 1px solid #E2E4EB;
}
.body-card{
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #E2E4EB;
  border-top: none;
}
.body-card .card-body {
  padding: 15px 7px;
}
.body-card-screen{
  padding: 10px 20% 25px 5%;
  border-radius: 0px;
  border: 0px;
}
.task-card, .task-card-small{
  border-radius: 15px;
  margin-bottom: 10px;
}
::v-deep .task-card .card-body {
  padding: 1rem;
}
::v-deep .task-card-small .card-body {
  padding: 0.5rem 0.5rem 0.75rem 0.5rem;
}
.follow-up-task-small{
  background:#DEF1F0;
  padding:5px;
  border-radius:10px;
  color: #011828;
  font-size: 12px;
}
.past-due {
  background:#FCDEDC;
  padding:5px;
  border-radius:10px;
}
.soon-due {
  background: #FFF2CF;
  padding:5px;
  border-radius:10px;
}
::v-deep .check-circle-fill {
  color: transparent;
}
::v-deep .check-circle-fill:hover {
  color: #F3F8FD;
}
.task-body{
  padding: 20px 25px;
}
.task-body-small{
  padding: 15px 15px;
}
.task-card .data-card,
.task-card-small .data-card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 38px;
  max-width:175px;
  margin-right:none;
  border-radius: 5px;
  padding-left:15px;
  padding-right:15px;
  font-weight:500;
  font-size: 14px;
  background:#F7F8FA;
}
.filter-button {
  border-top: 0px;
  border-right: 0px;
  border-bottom: 0px;
}

/* styling the nav pill filter group */
::v-deep .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color: var(--blue);
}
::v-deep a {
    color: var(--blue);
    font-size: 14px;
}
::v-deep .nav-link:hover {
  background-color: #EEF1FA;
}

/* Styling the search bar group */
::v-deep .search-group {
    position: relative;
    border: 1px solid #C9D2DF;
    border-radius: 5px;
    max-width:650px;
}
::v-deep .task-search-input, .input-group-text {
    height:50px;
    border: none;
    border-radius: 5px;
    background: white;
    color: #9CA3AD; 
}
::v-deep .task-search-input::placeholder {
    color: #9CA3AD;
    font-size:12px;
    opacity: 1;
}
::v-deep .b-dropdown .btn-secondary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    border-radius: 5px;
    color: #9CA3AD;
    background: white;
    font-size:12px;
    height:50px;
    min-width:150px;
    text-align:left;
}
::v-deep .b-dropdown .btn-secondary:active,
::v-deep .btn-secondary:not(:disabled):not(.disabled):active, 
::v-deep .btn-secondary:not(:disabled):not(.disabled).active, 
::v-deep .show > .btn-secondary.dropdown-toggle {
    color:#519EFF !important;
    background-color: white !important;
}
::v-deep .b-dropdown .btn-secondary:focus {
    box-shadow:none !important;
}
.divider {
    display: inline-block;
    width: 0;
    height: 20px;
    margin: auto;
    border-right: 1.8px solid #D2D8E2;
}

/* dropdown menu-related styling */
::v-deep .filter-dropdown .b-dropdown-form {
    padding: 20px 30px 30px 30px;
}
::v-deep .filter-dropdown .dropdown-menu {
    width:100.4%;
    right: -1px !important;
    left: auto !important;
    top:45px !important;
    transform: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0px 16px 48px #1617182B !important;
}
/* assigned muliselect styling */
.assigned-multiselect ::v-deep .multiselect__single{
    font-weight: 500;
    font-size: 14px;
}
.worker-select {
  display: flex;
  padding-left:25px
}
.worker-select-small {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.worker-select ::v-deep .multiselect__select {
  padding-top:16px !important;
}
.worker-select ::v-deep .multiselect--active .multiselect__select {
  padding-top:12px !important;
}
.error-text {
    color: #f05c50;
    font: 14px;
    text-align:center;
    margin-top: 10px;
}
</style>